import React, { useEffect, useState } from "react";
import { notification } from "antd";
import Spinner from "react-bootstrap/Spinner";

import {
  Button,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import globalConst from "../helpers/global";
import { getRestaurantInfo } from "../actions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { validatePhone, validEmail, validName } from "../helpers/regex";
import moment from "moment";
import countryCodesList from "country-codes-list";
import NotFoundGif from "../assets/notfoundgif.gif";
import TextArea from "antd/lib/input/TextArea";
import GetAllData from "./GetAllData";

const myCountryCodesObject = countryCodesList.customList(
  "countryCode",
  "[{countryCode}]:{countryCallingCode}"
);

const allCountryCodes = Object.values(myCountryCodesObject);

const ReserveTable = (props) => {
  const slug = props.match.params.slug;
  const [loading, setLoading] = useState(false);
  const [countryCode, setcountryCode] = useState(47);
  const [open, setOpen] = useState(false);
  const [numberOfSeats, setNumberOfSeats] = useState(1);
  const [numberOfSeatsChildren, setNumberOfSeatsChildren] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [reserveTableId, setReserveTableId] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [price, setPrice] = useState();
  const [newData, setNewData] = useState([]);
  const [showProducts, setShowProducts] = useState(false);
  const [data, setData] = useState({});

  const dispatch = useDispatch();
  const format = "HH:mm";
  const [formObject, setFormObject] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    date: "",
    time: "",
    note: ""
  });

  const [formError, setFormError] = useState({});

  const {
    id,
    logo,
    name,
    additional,
    error,
    err_status,
    availableSeats
  } = useSelector((state) => state.restaurant);
  const openNotification = (message, desc) => {
    notification.open({
      message: message,
      description: desc
    });
  };

  useEffect(() => {}, [showProducts]);

  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmit) {
      console.log("no errors");
      setLoading(true);
      const data = {
        ...formObject,
        branchId: id,
        numberOfSeats,
        countryCode,
        numberOfSeatsChildren
      };
      reserveTable(`${globalConst.serverip}api/v1/public/reservetable`, data);
    }
  }, [formError]);

  async function getReserVePrice() {
    try {
      let response = await fetch(
        `${globalConst.serverip}api/v1/admin/branch/reservetableprice`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      if (!response.ok) {
        throw new Error("Something went wrong getting price");
      }

      if (response.status == 200) {
        let res = await response.json();
        setPrice(res.data.price);
      }
    } catch (error) {
      setPrice("0");
    }
  }

  useEffect(() => {
    dispatch(getRestaurantInfo(slug));
    getReserVePrice();
  }, []);

  useEffect(() => {
    if (reserveTableId && price < 1) {
      payWithTidyPay();
    }
  }, [reserveTableId, price]);

  const reserveTable = async (url, data) => {
    try {
      const res = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json"
        }
      });
      if (res.data.status == 200) {
        setReserveTableId(res.data.data.id);
        setPrice(res.data.data.price);

        if (res.data.data.price > 0) {
          setIsVisible(true);
        }

        setLoading(false);
      } else {
        setIsVisible(false);
        setLoading(false);
        openNotification("Error", res.data.message);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const validateForm = (values) => {
    const errors = {};

    if (values.firstName === "") {
      errors.firstName = "*Please enter first name";
    } else if (!validName.test(values.firstName)) {
      errors.firstName = "*Please enter a valid first name";
    }

    if (values.lastName === "") {
      errors.lastName = "*Please enter last name";
    } else if (!validName.test(values.lastName)) {
      errors.lastName = "*Please enter a valid last name";
    }

    if (values.phoneNumber === "") {
      errors.phoneNumber = "*Please enter your phone number";
    } else if (!validatePhone.test(values.phoneNumber)) {
      errors.phoneNumber = "*Please enter a valid phone number";
    }

    if (values.email === "") {
      errors.email = "*Please enter email address";
    } else if (!validEmail.test(values.email)) {
      errors.email = "*Please provide a valid email address";
    }

    if (values.date === "") {
      errors.date = "*Please enter  date";
    } else {
      const today = new Date();
      var d1 = new Date(moment().subtract(1, "days"));
      var d2 = new Date(values.date);
      if (d1.getTime() > d2.getTime()) {
        errors.date = "*Please enter  a valid date";
      }
    }

    if (values.time === "") {
      errors.time = "*Please select time";
    }
    return errors;
  };

  const branchId = {
    branchId,
    type: "id"
  };

  // Get restaurant products
  const getRestaurantProducts = (e) => {
    e.preventDefault();

    ///Form validation
    setFormError(validateForm(formObject));
    if (Object.keys(formError).length === 0) {
      //Check if any of the form fields is empty
      if (
        formObject.firstName === "" ||
        formObject.lastName === "" ||
        formObject.phoneNumber === "" ||
        formObject.email === "" ||
        formObject.date === "" ||
        formObject.time === ""
      ) {
        console.log("Please fill in all required fields.");
        return;
      }
      // console.log("formObj komal", formObject);
      const data = {
        ...formObject,
        firstName: formObject.firstName,
        lastName: formObject.lastName,
        phoneNumber: formObject.phoneNumber,
        email: formObject.email,
        date: formObject.date,
        time: formObject.time,
        note: formObject.note,
        numberOfSeats: numberOfSeats,
        countryCode: countryCode,
        numberOfSeatsChildren: numberOfSeatsChildren,
        branchId: id
      };
      setData(data);
    }

    if (!branchId) {
      // console.log(
      //   "branchId is empty or undefined. Please provide a valid branchId."
      // );
      return;
    }

    // console.log("---getting restaurant products----");

    setLoading(true);

    axios
      .post(`${globalConst.serverip}api/v1/public/reserveTableProducts`, {
        branchId: "5fb556a2949ee4ab9dcbc94a",
        // branchId: branchId,
        type: "id"
      })
      .then((res) => {
        // console.log("--------------res-data----------------------", res.data);
        setNewData(res.data.data);
        setShowProducts(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const submitForm = (e) => {
    e.preventDefault();
    setFormError(validateForm(formObject));
    setIsSubmit(true);
  };

  const handleInputChange = (e) => {
    let value = e.target.value;

    setFormObject({ ...formObject, [e.target.name]: value });
  };

  const payWithTidyPay = async () => {
    // console.log("---Table ID is:---", reserveTableId);
    // return;
    try {
      setLoading(true);
      const response = await axios.post(
        `${globalConst.serverip}api/v1/public/processpayment`,
        { id: reserveTableId },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );

      // console.log("response", response);
      //return;
      window.open(response.data.url, "_self");
      setLoading(false);
    } catch (error) {
      console.log("error");
      setLoading(false);
    }
  };

  const chechAvailSeats = (type) => {
    if (type == "adult") {
      numberOfSeats + numberOfSeatsChildren < availableSeats
        ? setNumberOfSeats(numberOfSeats + 1)
        : openNotification(
            "Sorry",
            `Currently Only ${availableSeats}  seats are available`
          );
    } else {
      numberOfSeats + numberOfSeatsChildren < availableSeats
        ? setNumberOfSeatsChildren(numberOfSeatsChildren + 1)
        : openNotification(
            "Sorry",
            `Currently  Only ${availableSeats}  Seats are available`
          );
    }
  };

  const onCountryCodeSelectHandler = (e) => {
    let code = e.target.value.split(":")[1];
    setcountryCode(code);
  };

  const getRestaurantList = () => {
    alert("hellow");
  };

  return (
    <>
      {loading ? (
        <div className="d-flex align-items-center justify-content-center loading ">
          <Spinner
            animation="border"
            variant="light"
            className="d-flex justify-content-center  align-items-center"
            style={{
              height: "120px",
              width: "120px",
              borderStyle: "solid",
              borderWidth: "8px"
            }}
          />
        </div>
      ) : (
        <>
          {!showProducts && (
            <Container
              fluid
              className="d-flex justify-content-center align-items-center main-container"
            >
              {(err_status == 404 ||
                (additional && additional.reserveTablePermission == false)) && (
                <Row className="d-flex justify-content-between">
                  <div className="w-100 d-flex justify-content-center align-items-center pb-3">
                    <img src={NotFoundGif} alt="Page Not Found" width="600px" />
                  </div>
                </Row>
              )}
              {/* {console.log("{availableSeats}",availableSeats)} */}

              {err_status != 404 &&
                additional &&
                additional.reserveTablePermission && (
                  <Row
                    className="d-flex flex-column align-items-center"
                    id="reserveTable"
                  >
                    <div className="text-center reserveHead">
                      <div>
                        <img
                          src={`${globalConst.s3url}${logo}`}
                          alt="Restaurant Logo"
                          height="140px"
                        />
                        <h5 className="text-center">{name}</h5>
                        <h2 className="text-center">RESERVER BORD</h2>
                        <h5 className="text-center">
                          {availableSeats
                            ? `Total Available Seats:${availableSeats}`
                            : ""}
                        </h5>
                      </div>
                      {/* <img
                        src={`${globalConst.s3url}${logo}`}
                        alt="Restaurant Logo"
                        height="140px"
                      />
                      <h5 className="text-center">{name}</h5>
                      <h2 className="text-center">RESERVER BORD</h2>
                      <h5 className="text-center">
                        {availableSeats
                          ? `Total Available Seats:${availableSeats}`
                          : ""}
                      </h5> */}

                      <div className="d-flex justify-content-center add-btn pb-3">
                        <div className="d-flex justify-content-between align-items-center p-2">
                          {/* <span>Antall personer</span> */}
                          <h4 className="mb-0 pr-3">Voksen</h4>
                          <div
                            className="d-flex align-items-center justify-content-center boxShadow"
                            style={{ fontSize: "25px" }}
                          >
                            <button
                              onClick={() =>
                                numberOfSeats > 1 &&
                                setNumberOfSeats(numberOfSeats - 1)
                              }
                            >
                              -
                            </button>{" "}
                            &nbsp; {numberOfSeats} &nbsp;
                            <button
                              onClick={() =>
                                availableSeats
                                  ? chechAvailSeats("adult")
                                  : setNumberOfSeats(numberOfSeats + 1)
                              }
                            >
                              +
                            </button>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center p-2 ">
                          <h4 className="mb-0 px-3">Barn</h4>
                          <div
                            className="d-flex align-items-center justify-content-center boxShadow"
                            style={{ fontSize: "25px" }}
                          >
                            <button
                              onClick={() =>
                                numberOfSeatsChildren > 0 &&
                                setNumberOfSeatsChildren(
                                  numberOfSeatsChildren - 1
                                )
                              }
                            >
                              -
                            </button>{" "}
                            &nbsp; {numberOfSeatsChildren} &nbsp;
                            <button
                              onClick={() =>
                                availableSeats
                                  ? chechAvailSeats("child")
                                  : setNumberOfSeatsChildren(
                                      numberOfSeatsChildren + 1
                                    )
                              }
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>

                      <div style={{ width: "100%" }}>
                        <div className="main-row d-flex justify-content-between pb-1 w-100">
                          <FormGroup className="mb-sm-0 d-flex justify-content-between">
                            <Label for="FIRSTNAME">
                              Fornavn <span className="text-danger">*</span>{" "}
                            </Label>
                            <Input
                              className={formError.firstName && "reserveInput"}
                              type="text"
                              name="firstName"
                              id="FIRSTNAME"
                              value={formObject.firstName}
                              onChange={handleInputChange}
                            />
                            {formError.firstName && (
                              <p
                                style={{ color: "red" }}
                                className="errorMessage"
                              >
                                {formError.firstName}
                              </p>
                            )}
                          </FormGroup>

                          <FormGroup className=" mb-sm-0 d-flex justify-content-between">
                            <Label for="LASTNAME">
                              Etternavn <span className="text-danger">*</span>
                            </Label>
                            <Input
                              className={formError.lastName && "reserveInput"}
                              type="text"
                              name="lastName"
                              id="LASTNAME"
                              placeholder=""
                              value={formObject.lastName}
                              onChange={handleInputChange}
                            />
                            {formError.lastName && (
                              <p
                                style={{ color: "red" }}
                                className="errorMessage"
                              >
                                {formError.lastName}
                              </p>
                            )}
                          </FormGroup>
                        </div>

                        <div className="main-row d-flex justify-content-between pb-1 pt-1 w-100">
                          <FormGroup className="mb-sm-0 d-flex justify-content-between">
                            <Label for="=Number">
                              Telefon <span className="text-danger">*</span>
                            </Label>
                            <div className="d-flex">
                              <Input
                                type="select"
                                name="select"
                                id="selectField"
                                onChange={onCountryCodeSelectHandler}
                                defaultValue={"[BV]:47"}
                              >
                                {allCountryCodes.map((cn) => {
                                  return (
                                    <option value={cn}>
                                      {cn.split(":")[1]}
                                    </option>
                                  );
                                })}
                              </Input>
                              <Input
                                className={
                                  formError.phoneNumber && "reserveInput"
                                }
                                type="text"
                                name="phoneNumber"
                                id="PHONENUMBER"
                                placeholder=""
                                value={formObject.phoneNumber}
                                onChange={handleInputChange}
                              />
                            </div>
                            {formError.phoneNumber && (
                              <p
                                style={{ color: "red" }}
                                className="errorMessage"
                              >
                                {formError.phoneNumber}
                              </p>
                            )}
                          </FormGroup>

                          <FormGroup className="mb-sm-0 d-flex justify-content-between">
                            <Label for="emailId">
                              E-Post <span className="text-danger">*</span>
                            </Label>
                            <Input
                              className={formError.email && "reserveInput"}
                              type="text"
                              name="email"
                              id="emailId"
                              placeholder=""
                              value={formObject.email}
                              onChange={handleInputChange}
                            />
                            {formError.email && (
                              <p
                                style={{ color: "red" }}
                                className="errorMessage"
                              >
                                {formError.email}
                              </p>
                            )}
                          </FormGroup>
                        </div>

                        <div className="main-row d-flex justify-content-between pb-1 pt-1 w-100">
                          <FormGroup className="mb-sm-0 d-flex justify-content-between">
                            <Label for="exampleDate">
                              Dato <span className="text-danger">*</span>
                            </Label>

                            <Input
                              className={formError.date && "reserveInput"}
                              type="date"
                              name="date"
                              id="exampleDate"
                              placeholder=""
                              value={formObject.date}
                              onChange={handleInputChange}
                            />
                            {formError.date && (
                              <p
                                style={{ color: "red" }}
                                className="errorMessage"
                              >
                                {formError.date}
                              </p>
                            )}
                          </FormGroup>

                          <FormGroup className="mb-sm-0 d-flex justify-content-between">
                            <Label for="exampleTime">
                              Tid <span className="text-danger">*</span>
                            </Label>
                            <Input
                              className={formError.time && "reserveInput"}
                              type="time"
                              name="time"
                              id="exampleTime"
                              placeholder=""
                              value={formObject.time}
                              onChange={handleInputChange}
                            />
                            {formError.time && (
                              <p
                                style={{ color: "red" }}
                                className="errorMessage"
                              >
                                {formError.time}
                              </p>
                            )}
                          </FormGroup>
                        </div>

                        <div className="main-row d-flex pb-1 pt-1 w-100">
                          <FormGroup className="mb-sm-0 w-100 d-flex justify-content-between">
                            <Label for="Note">Merk</Label>
                            <TextArea
                              type="textarea"
                              name="note"
                              id="Note"
                              autoSize={{ minRows: 3, maxRows: 4 }}
                              maxLength="500"
                              value={formObject.note}
                              onChange={handleInputChange}
                            />
                          </FormGroup>
                        </div>

                        <Button
                          type="submit"
                          className="sendBooking mt-3"
                          onClick={submitForm}
                        >
                          Send Bestilling
                        </Button>

                        <Button
                          type="submit"
                          className="sendBooking mt-3 ml-5"
                          onClick={getRestaurantProducts}
                        >
                          Reserver mat
                        </Button>
                      </div>
                    </div>
                  </Row>
                )}

              {isVisible && (
                <Modal isOpen={isVisible}>
                  <ModalHeader
                    toggle={() => {
                      setIsVisible(false);
                    }}
                  >
                    Bekreft bestillingen
                  </ModalHeader>
                  <ModalBody>
                    <p>
                      Din reservasjons sum blir konvertert til et gavekort som
                      du mottar kort tid etter betalingen er fullført.
                    </p>
                    <h4 className="text-center">{`Betal ${price} kr`}</h4>
                  </ModalBody>
                  <ModalFooter className="d-flex justify-content-center align-items-center">
                    <Button color="primary" onClick={payWithTidyPay}>
                      Pay
                    </Button>
                  </ModalFooter>
                </Modal>
              )}
            </Container>
          )}

          {showProducts && (
            <div className="reserveTableOrdering">
              <button
                className="position-fixed"
                style={{
                  fontSize: "13px",
                  backgroundColor: "red",
                  color: "white",
                  border: "0",
                  top: 0,
                  right: 0,
                  zIndex: 9999
                }}
                onClick={() => setShowProducts(false)}
              >
                Back
              </button>

              <GetAllData alldata={newData} data={data} />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ReserveTable;
