import React, { Component } from "react"; // importing main react
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
  Col,
  Container,
  Alert,
  Collapse,
  // img,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  // CardDeck,
  CardText,
  Badge
} from "reactstrap";

import warning from "../assets/Something went wrong.jpg";
import reload from "../assets/reload.png";
import { SpinnerRoundFilled } from "spinners-react";
import moment from "moment";
import closedrest from "../assets/closedthree.png";
import openSocket from "socket.io-client";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import "./plu1tablemenu.css";
import {
  fetchProducts,
  toggleModal,
  onlytoggleModal,
  addQuantity,
  removeQuantity,
  addItems,
  getCart,
  removeFromCart,
  removeExtraFromCart,
  handleQuantityChange,
  deliveryOptionsPopup,
  getRestaurantInfo,
  resetOrderPage,
  resetModal,
  handleInstructionChange,
  getsupercategory,
  getExtraItems,
  clearStorage,
  get_categories,
  get_products,
  get_plu1Table_products,
  placeTableOnlineOrder,
  tableOrder_payment_hitting,
  addExtraPrice,
  getPlu1Table_categories,
  reduceExtraPrice,
  addExtraItems,
  reduceExtraItems,
  AddTableToCart,
  payment_hitting
} from "../actions";
import classnames from "classnames";
import globalConst from "../helpers/global";
import Cart from "./Cart";
import Header from "./Header";
import { grey } from "color-name";
import tempimage from "../assets/pexels-photo-7245469.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faCartPlus,
  faHamburger,
  faArrowCircleUp
} from "@fortawesome/free-solid-svg-icons";
import url from "socket.io-client/lib/url";
import products from "../reducers/productsReducer";
import NewOrderNowCom from "./NewOrderNowCom";
import { addItemToCart, deleteCart } from "../actions/cartActions";
const socket = openSocket(`${globalConst.serverip}`);

class PublicTablicMenu extends Component {
  tableNumber = 0;
  constructor(props) {
    super(props);
    this.state = {
      weekday: "",
      currentTime: moment().hour(),
      restaurantClosed: true,
      toggleCart: false,
      prodsData: [],
      currentsuperCategory: "",
      currentcatType: {},
      currentcategoryImage: "",
      currentCategoryName: "",
      checAvFlag: false,
      tryagainCount: 1,
      daySchedule: {},
      currentProduct: {},
      currentProductLoading: false,
      currentPram: this.props.match.params.id,
      categoryBackColor: "",
      currentColor: "",
      incrementChecked: [],
      ingredentsDescription: "",
      ingredentsDesCArray: [],
      incrementCheckedIds: [],
      extraItems: [],
      checkedExtra: [],
      extraItemsOpen: [],
      isTopButon: false,
      isVisible: false,
      currentProductToPass: {}
    };

    //   this.props.dispatch(clearStorage());
  }

  handleOk = (item) => {
    this.props.dispatch(addItemToCart(item));

    this.setState({ isVisible: false });
  };
  handleCancel = () => {
    this.setState({ isVisible: false });
  };

  async localTime() {
    var localSchedule = await localStorage.getItem("daySchedule");
    return localSchedule;
  }

  placeOrder = () => {
    const { resId, userId, cart, totalPrice } = this.props;

    this.props.dispatch(
      placeTableOnlineOrder(resId, userId, cart, totalPrice, "table")
    );
  };

  componentDidMount = async () => {
    const restaurantId = this.props.match.params.slug;
    this.tableNumber = JSON.parse(localStorage.getItem("tableData"));

    if (!this.tableNumber) {
      this.props.history.replace("/restaurant/booktable/" + restaurantId);
    }

    window.addEventListener("scroll", this.listenToScroll);

    this.props.dispatch(getPlu1Table_categories(restaurantId));
    this.props.dispatch(resetOrderPage());
    this.props.dispatch(resetModal());
    this.props.dispatch(getCart());
    this.props.dispatch(fetchProducts(restaurantId));
    this.props.dispatch(getRestaurantInfo(restaurantId));
    this.props.dispatch(getExtraItems(restaurantId));
  };

  listenToScroll = () => {
    if (window.pageYOffset >= 1000) {
      this.setState((state) => {
        if (state.isTopButon) {
          return;
        }
        return { isTopButon: true };
      });
    } else {
      this.setState((state) => {
        if (!state.isTopButon) {
          return;
        }
        return { isTopButon: false };
      });
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    const restaurantId = this.props.match.params.slug;
    this.tableNumber = JSON.parse(localStorage.getItem("tableData"));

    if (!this.tableNumber) {
      this.props.history.replace("/restaurant/booktable/" + restaurantId);
    }

    if (prevProps.getCategoriesSuccess !== this.props.getCategoriesSuccess) {
      let dt = await this.props.categoriesData;
      let id = dt && dt.length ? dt[0]._id : "";
      if (id) {
        this.getProds(dt[0]);
      }
    }

    if (prevProps.getProductsSuccess != this.props.getProductsSuccess) {
      let dt = await this.props.productsData;

      if (dt.length) {
        await this.setState({
          prodsData: this.props.productsData,
          currentProduct: this.props.productsData[0]
        });
      } else {
        await this.setState({
          prodsData: [],
          currentProduct: ""
        });
      }
    }
  };

  toggleCartFunc = (value) => {
    window.scrollTo(0, 0);
    this.setState({ toggleCart: !value });
  };
  // dspth = async restaurantId => {
  //   var { dispatch } = this.props;
  //   await dispatch(getRestaurantInfo(restaurantId));
  //   this.checkWeekAvailability();
  // };
  checkWeekAvailability = async () => {
    // window.location.reload();
    // alert("GOT IN");
    this.setState({ checAvFlag: true, tryagainCount: 0 });
    var day = moment().day();
    if (day === 1) {
      if (this.props.monday && this.props.monday.status === true) {
        let optime =
          this.props.monday && this.props.monday.openingTime
            ? this.props.monday.openingTime
            : "00:00";
        optime = optime.split(":");
        let clostime =
          this.props.monday && this.props.monday.closingTime
            ? this.props.monday.closingTime
            : "00:00";
        clostime = clostime.split(":");
        if (
          this.props.monday &&
          this.props.monday.status === true &&
          Number(optime[0]) <= Number(moment().hour()) &&
          Number(clostime[0]) >= Number(moment().hour())
        ) {
          // opening time
          if (Number(optime[0]) === Number(moment().hour())) {
            if (Number(optime[1] <= Number(moment().minutes()))) {
              await this.setState({ restaurantClosed: false });
            } else {
              await this.setState({ restaurantClosed: true });
            }
          } else {
            await this.setState({ restaurantClosed: false });
          }
          // close time 17:30 -  17:25
          if (Number(clostime[0]) === Number(moment().hour())) {
            if (Number(clostime[1]) - Number(moment().minutes()) > 0) {
              await this.setState({ restaurantClosed: false });
            } else {
              await this.setState({ restaurantClosed: true });
            }
          }
        }
      } else {
        await this.setState({ restaurantClosed: true });
      }
    } else if (day === 2) {
      //  =========== START OF DAY TWO ==============
      if (this.props.tuesday && this.props.tuesday.status === true) {
        let optime =
          this.props.tuesday && this.props.tuesday.openingTime
            ? this.props.tuesday.openingTime
            : "00:00";
        optime = optime.split(":");
        let clostime =
          this.props.tuesday && this.props.tuesday.closingTime
            ? this.props.tuesday.closingTime
            : "00:00";
        clostime = clostime.split(":");
        if (
          this.props.tuesday &&
          this.props.tuesday.status === true &&
          Number(optime[0]) <= Number(moment().hour()) &&
          Number(clostime[0]) >= Number(moment().hour())
        ) {
          // opening time
          if (Number(optime[0]) === Number(moment().hour())) {
            if (Number(optime[1] <= Number(moment().minutes()))) {
              await this.setState({ restaurantClosed: false });
            } else {
              await this.setState({ restaurantClosed: true });
            }
          } else {
            await this.setState({ restaurantClosed: false });
          }
          // close time 17:30 -  17:25
          if (Number(clostime[0]) === Number(moment().hour())) {
            if (Number(clostime[1]) - Number(moment().minutes()) > 0) {
              await this.setState({ restaurantClosed: false });
            } else {
              await this.setState({ restaurantClosed: true });
            }
          }
        }
      } else {
        await this.setState({ restaurantClosed: true });
      }
    } else if (day === 3) {
      //  =========== START OF DAY THREE ==============
      if (this.props.wednesday && this.props.wednesday.status === true) {
        let optime =
          this.props.wednesday && this.props.wednesday.openingTime
            ? this.props.wednesday.openingTime
            : "00:00";
        optime = optime.split(":");
        let clostime =
          this.props.wednesday && this.props.wednesday.closingTime
            ? this.props.wednesday.closingTime
            : "00:00";
        clostime = clostime.split(":");
        if (
          this.props.wednesday &&
          this.props.wednesday.status === true &&
          Number(optime[0]) <= Number(moment().hour()) &&
          Number(clostime[0]) >= Number(moment().hour())
        ) {
          // opening time
          if (Number(optime[0]) === Number(moment().hour())) {
            if (Number(optime[1] <= Number(moment().minutes()))) {
              await this.setState({
                restaurantClosed: false,
                checAvFlag: false
              });
            } else {
              await this.setState({
                restaurantClosed: true,
                checAvFlag: false
              });
            }
          } else {
            await this.setState({ restaurantClosed: false, checAvFlag: false });
          }
          // close time 17:30 -  17:25
          if (Number(clostime[0]) === Number(moment().hour())) {
            if (Number(clostime[1]) - Number(moment().minutes()) > 0) {
              await this.setState({
                restaurantClosed: false,
                checAvFlag: false
              });
            } else {
              await this.setState({
                restaurantClosed: true,
                checAvFlag: false
              });
            }
          }
        }
      } else {
        await this.setState({ restaurantClosed: true });
      }
    } else if (day === 4) {
      //  =========== START OF DAY FOUR ==============
      if (this.props.thursday && this.props.thursday.status === true) {
        let optime =
          this.props.thursday && this.props.thursday.openingTime
            ? this.props.thursday.openingTime
            : "00:00";
        optime = optime.split(":");
        let clostime =
          this.props.thursday && this.props.thursday.closingTime
            ? this.props.thursday.closingTime
            : "00:00";
        clostime = clostime.split(":");
        if (
          this.props.thursday &&
          this.props.thursday.status === true &&
          Number(optime[0]) <= Number(moment().hour()) &&
          Number(clostime[0]) >= Number(moment().hour())
        ) {
          // opening time
          if (Number(optime[0]) == Number(moment().hour())) {
            if (Number(optime[1] <= Number(moment().minutes()))) {
              await this.setState({ restaurantClosed: false });
            } else {
              await this.setState({ restaurantClosed: true });
            }
          } else {
            await this.setState({ restaurantClosed: false });
          }
          // close time 17:30 -  17:25
          if (Number(clostime[0]) === Number(moment().hour())) {
            if (Number(clostime[1]) - Number(moment().minutes()) > 0) {
              await this.setState({ restaurantClosed: false });
            } else {
              await this.setState({ restaurantClosed: true });
            }
          }
        }
      } else {
        await this.setState({ restaurantClosed: true });
      }
    } else if (day === 5) {
      //  =========== START OF DAY FIVE ==============
      if (this.props.friday && this.props.friday.status === true) {
        let optime =
          this.props.friday && this.props.friday.openingTime
            ? this.props.friday.openingTime
            : "00:00";
        optime = optime.split(":");
        let clostime =
          this.props.friday && this.props.friday.closingTime
            ? this.props.friday.closingTime
            : "00:00";
        clostime = clostime.split(":");
        if (
          this.props.friday &&
          this.props.friday.status === true &&
          Number(optime[0]) <= Number(moment().hour()) &&
          Number(clostime[0]) >= Number(moment().hour())
        ) {
          // opening time
          if (Number(optime[0]) === Number(moment().hour())) {
            if (Number(optime[1] <= Number(moment().minutes()))) {
              await this.setState({ restaurantClosed: false });
            } else {
              await this.setState({ restaurantClosed: true });
            }
          } else {
            await this.setState({ restaurantClosed: false });
          }
          // close time 17:30 -  17:25
          if (Number(clostime[0]) === Number(moment().hour())) {
            if (Number(clostime[1]) - Number(moment().minutes()) > 0) {
              await this.setState({ restaurantClosed: false });
            } else {
              await this.setState({ restaurantClosed: true });
            }
          }
        }
      } else {
        await this.setState({ restaurantClosed: true });
      }
    } else if (day === 6) {
      //  =========== START OF DAY SIX ==============
      if (this.props.saturday && this.props.saturday.status === true) {
        let optime =
          this.props.saturday && this.props.saturday.openingTime
            ? this.props.saturday.openingTime
            : "00:00";
        optime = optime.split(":");
        let clostime =
          this.props.saturday && this.props.saturday.closingTime
            ? this.props.saturday.closingTime
            : "00:00";
        clostime = clostime.split(":");
        if (
          this.props.saturday &&
          this.props.saturday.status === true &&
          Number(optime[0]) <= Number(moment().hour()) &&
          Number(clostime[0]) >= Number(moment().hour())
        ) {
          // opening time
          if (Number(optime[0]) === Number(moment().hour())) {
            if (Number(optime[1] <= Number(moment().minutes()))) {
              await this.setState({ restaurantClosed: false });
            } else {
              await this.setState({ restaurantClosed: true });
            }
          } else {
            await this.setState({ restaurantClosed: false });
          }
          // close time 17:30 -  17:25
          if (Number(clostime[0]) === Number(moment().hour())) {
            if (Number(clostime[1]) - Number(moment().minutes()) > 0) {
              await this.setState({ restaurantClosed: false });
            } else {
              await this.setState({ restaurantClosed: true });
            }
          }
        }
      } else {
        await this.setState({ restaurantClosed: true });
      }
    } else if (day === 0) {
      //  =========== START OF DAY SEVEN ==============
      if (this.props.sunday && this.props.sunday.status === true) {
        let optime =
          this.props.sunday && this.props.sunday.openingTime
            ? this.props.sunday.openingTime
            : "00:00";

        optime = optime.split(":");
        let clostime =
          this.props.sunday && this.props.sunday.closingTime
            ? this.props.sunday.closingTime
            : "00:00";
        clostime = clostime.split(":");
        if (
          this.props.sunday &&
          this.props.sunday.status === true &&
          Number(optime[0]) <= Number(moment().hour()) &&
          Number(clostime[0]) >= Number(moment().hour())
        ) {
          // opening time
          if (Number(optime[0]) === Number(moment().hour())) {
            if (Number(optime[1] <= Number(moment().minutes()))) {
              await this.setState({ restaurantClosed: false });
            } else {
              await this.setState({ restaurantClosed: true });
            }
          } else {
            await this.setState({ restaurantClosed: false });
          }
          // close time 17:30 -  17:25
          if (Number(clostime[0]) === Number(moment().hour())) {
            if (Number(clostime[1]) - Number(moment().minutes()) > 0) {
              await this.setState({ restaurantClosed: false });
            } else {
              await this.setState({ restaurantClosed: true });
            }
          }
        }
      } else {
        await this.setState({ restaurantClosed: true });
      }
    }
  };

  onlytoggleModal = () => {
    this.props.dispatch(onlytoggleModal());
  };

  toggle = (product, spcat, catType) => {
    this.props.dispatch(getsupercategory(spcat));
    var dt = this.props.supercategoryData;
    var prod = {
      _id: product._id,
      isDeleted: product.isDeleted,
      employeeId: product.employeeId,
      categoryId: product.categoryId,
      branchId: product.branchId,
      image: product.image,
      name: product.name,
      price: product.price,
      itemNo: product.itemNo,
      __v: 0,
      quantity: product.quantity,
      totalPrice: product.totalPrice,
      instruction: product.instruction,
      superCategory: spcat,
      catType: catType,
      qtyTrack: [],
      ingredents: product.ingredents ? product.ingredents : [],
      status: "IN-PROCESS"
    };
    fetch(`${globalConst.serverip}public/getsupercat/${spcat}`)
      .then((res) => res.json())
      .then((json) => {
        var superCatData = json.data;
        prod.sCatType = superCatData.sCatType;

        this.props.dispatch(toggleModal(prod));
        this.setState({
          incrementChecked: []
        });
        let ing = product.ingredents ? product.ingredents : [];
        for (let i = 0; i < ing.length; i++) {
          let chek = [...this.state.incrementChecked];
          chek.push(true);
          this.setState({
            incrementChecked: chek,
            ingredentsDesCArray: [],
            incrementCheckedIds: [],
            ingredentsDescription: ""
          });
        }
      })
      .catch((error) => {});
    const { extra } = this.props;
    this.state.checkedExtra = [];
    this.state.extraItemsOpen = [];
    let chekEx = [];
    let isOpen = [];
    for (let [idx, ext] of extra.entries()) {
      chekEx.push([]);
      isOpen.push(false);
      for (let i = 0; i < ext.items.length; i++) {
        // let chekEx = this.state.checkedExtra
        //   ? [...this.state.checkedExtra]
        //   : [];
        chekEx[idx].push(false);
        // this.state.checkedExtra = chekEx;
      }
    }
    this.state.checkedExtra = chekEx;
    this.state.extraItemsOpen = isOpen;
  };

  incrementItem = () => {
    this.props.dispatch(addQuantity());
  };

  decreaseItem = () => {
    this.props.dispatch(removeQuantity());
  };

  addToCart = () => {
    this.props.dispatch(addItems());
  };

  removeItemFromCart = (id) => {
    this.props.dispatch(removeFromCart(id));
  };
  removeExtraFromCart = (proId, extraId, ix) => {
    this.props.dispatch(removeExtraFromCart({ proId, extraId, ix }));
  };

  handleChange = (e) => {
    const value = Number(e.target.value);
    this.props.dispatch(handleQuantityChange(value));
  };

  openDeliveryOptions = (type) => {
    this.props.dispatch(deliveryOptionsPopup(type));
  };

  handleInstruction = (e) => {
    this.props.dispatch(handleInstructionChange(e.target.value));
  };
  handleExtraOpen = (id) => {
    const { extraItemsOpen } = this.state;
    extraItemsOpen[id] = !extraItemsOpen[id];
    this.setState({ extraItemsOpen: extraItemsOpen });
  };
  handleAddExtraItems = async (e, ix, idx, exItem) => {
    const checkItm = [...this.state.checkedExtra];
    let itm = e.target.value;
    let isChk = !checkItm[ix][idx];
    checkItm[ix][idx] = isChk;
    if (isChk) {
      await this.props.dispatch(addExtraPrice(Number(exItem.price)));
      await this.props.dispatch(addExtraItems(exItem));
    } else {
      await this.props.dispatch(reduceExtraPrice(Number(exItem.price)));
      await this.props.dispatch(reduceExtraItems(exItem));
    }
    this.setState({
      checkedExtra: checkItm
    });
  };
  handleInstructionText = async (e, idx, id) => {
    let chek = [...this.state.incrementChecked];
    chek[idx] = !chek[idx];
    this.setState({
      incrementChecked: chek
    });
    let deskArray = [...this.state.ingredentsDesCArray];
    let CheckIds = [...this.state.incrementCheckedIds];
    let ind = `${e.target.value} not required`;
    let arr = [];
    let ids = [];
    if (await !chek[idx]) {
      deskArray.push(ind);
      CheckIds.push(id);
      arr = deskArray;
      ids = CheckIds;
    } else {
      arr = deskArray.filter((val) => {
        return ind != val;
      });
      ids = CheckIds.filter((val) => {
        return id != val;
      });
    }
    let desc = arr.length > 0 ? arr.join(", ") : "";
    desc = `${desc},`;
    this.props.dispatch(handleInstructionChange({ desc, ids }));
    await this.setState({
      ingredentsDesCArray: arr,
      ingredentsDescription: desc,
      incrementCheckedIds: ids
    });
  };
  getProds = (itm) => {
    var { dispatch } = this.props;
    dispatch(get_plu1Table_products(itm._id));
    this.props.dispatch(getExtraItems(itm.superCategory));
    this.setState({
      currentCategoryName: itm.name,
      currentsuperCategory: itm.superCategory,
      currentcatType: itm.catType,
      currentcategoryImage: itm.image
    });
  };
  currentProductTopShow = async (prod) => {
    this.setState({ currentProductLoading: true });
    window.scrollTo(0, 0);
    await this.setState({ currentProduct: prod });
    this.setState({ currentProductLoading: false });
  };
  getIngredientDetails = (ingredents) => {
    let ingridientsElem = "";
    if (ingredents && ingredents.length > 0) {
      ingridientsElem = ingredents.map((sningredents, indx) => {
        return (
          <span key={indx}>
            <Badge
              style={{
                background: "#233344",
                color: "light",
                margin: "3px"
              }}
            >
              {sningredents && sningredents.stockproduct
                ? sningredents.stockproduct.name
                : ""}
            </Badge>
          </span>
        );
      });
    }
    return ingridientsElem;
  };

  render() {
    const { prodsData } = this.state;
    const {
      loading,
      items,
      cart,
      // activeTab,
      totalQuantity,
      totalPrice,
      modal,
      editingProduct,
      quantity,
      isOpen,
      popup,
      history,
      error,
      driveoutCharge,
      extraPrice,
      instruction
    } = this.props;

    if (loading) {
      return (
        // <div>Loading</div>
        <div
          style={{
            width: "100℅",
            height: "100vh",
            textAlign: "center",
            // display: "flex",
            // alignItem: "center",
            justifyContent: "center"
          }}
        >
          <SpinnerRoundFilled
            size={50}
            thickness={100}
            speed={100}
            color="#36ad47"
          />
        </div>
      );
    } else if (error) {
      return (
        <div
          style={{
            width: "50℅",
            height: "100vh",
            // textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            alignContent: "center",
            alignSelf: "center",
            justifyContent: "center"
          }}
        >
          <img src={warning} style={{ height: "65%", width: "50%" }} />
          <Button
            onClick={() => {
              localStorage.clear();

              window.location.reload();
            }}
            style={{
              borderRadius: 20,
              width: "30%",
              color: "white",
              fontWeight: "bold"
            }}
            color="warning"
          >
            Try again
            <img src={reload} style={{ height: 20, widht: 20 }} />
          </Button>
        </div>
      );
    } else {
      if (Number(this.state.tryagainCount) !== 0) {
        setTimeout(() => {
          this.checkWeekAvailability();
        }, 2500);
      }
      return (
        <Container className="plu-css" fluid={true}>
          <Row
            style={{
              // background: `#1B2F44`,
              background: `#000`,
              alignItems: "center",
              height: "8.2vh",
              position: "fixed",
              width: "100%",
              top: "0",
              left: "0",
              margin: "0",
              right: "0",
              zIndex: 100
            }}
          >
            <Col md="6" sm="3" xs="6">
              <img
                src={`${globalConst.s3url}${this.props.logo}`}
                width="50"
                height="50"
              />
            </Col>
            <Col md="6" sm="9" xs="6" style={{ textAlign: "right" }}>
              {this.props.itemsInCart.length > 0 && (
                <div>
                  <Link to="/tableorder/cart">
                    <FontAwesomeIcon
                      icon={faCartPlus}
                      color="white"
                      size="2x"
                      onClick={this.onClickCart}
                    />
                  </Link>
                  <Badge
                    style={{
                      position: "absolute",
                      right: "4px",
                      top: "-2px",
                      background: "#F0F2F5",
                      color: "white"
                    }}
                  >
                    {this.props.itemsInCart.length > 0
                      ? this.props.itemsInCart.length
                      : ""}
                  </Badge>
                </div>
              )}
            </Col>
          </Row>
          <Row style={{ marginTop: "5rem" }} className="tableMenu">
            {this.state.isTopButon && (
              <div
              >
                <a
                  type="button"
                  style={{ color: "#233344" }}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  <FontAwesomeIcon icon={faArrowCircleUp} size="3x" />
                </a>
              </div>
            )}
            <div id="dmenu" className="">
              <ul className="CategoryListing">
                {this.props.categoriesData &&
                !this.props.loading &&
                this.props.colors
                  ? this.props.categoriesData.map((itm, index) => {
                      return (
                        <li
                          key={itm._id}
                          onClick={() => this.getProds(itm)}
                          // onMouseEnter={()=> }
                          style={
                            itm.name === this.state.currentCategoryName
                              ? {
                                  // background: "rgb(214, 166, 102)",
                                  background: "#12c751",
                                  cursor: "pointer"
                                }
                              : {
                                  cursor: "pointer",
                                  color: "black"
                                }
                          }
                        >
                          <a
                            style={
                              itm.name === this.state.currentCategoryName
                                ? {
                                    color: "white"
                                  }
                                : {
                                    color: "black"
                                  }
                            }
                          >
                            {itm.name}
                          </a>
                        </li>
                      );
                    })
                  : null}
              </ul>
            </div>
          </Row>
          <Row>
            {!this.state.currentProductLoading &&
              prodsData &&
              prodsData.length > 0 &&
              this.props.colors &&
              prodsData.map((product, index) => {
                return (
                  <Col
                    key={index}
                    md="6"
                    lg="4"
                    sm="8"
                    style={{ margin: "auto", maxWidth: "450px" }}
                  >
                    <div style={{ margin: "0px" }}>
                      <div>
                        <Card>
                          {product.thumbnail ? (
                            <div className="productImage">
                              <CardImg
                                top
                                // width="100%"
                                className="thumbImage"
                                src={`${globalConst.s3url}${product.thumbnail}`}
                                alt="Card image cap"
                                // height="180px"
                              />
                            </div>
                          ) : (
                            <CardImg
                              top
                              width="180px"
                              // width="100%"
                              src={`${globalConst.s3url}${product.image}`}
                              alt="Card image cap"
                              // height="180px"
                            />
                          )}

                          <CardBody>
                            <>
                              <div
                                className="cardBorder"
                                // style={{
                                //   display: "flex",
                                //   justifyContent: "space-between"
                                // }}
                              >
                                <h6 style={{ fontSize: "16px" }}>
                                  {" "}
                                  {product.name}
                                </h6>
                                <h6 style={{ fontSize: "14px" }}>
                                  Kr. {product.price}
                                </h6>
                              </div>
                              {product.ingredentsDesc && (
                                <p className="productDesc">
                                  <b>Description:{" "}</b>
                                  {product.ingredentsDesc.substring(0, 110) +
                                    "..."}
                                </p>
                              )}
                            </>

                            {product.ingredents > 0 ? (
                              <CardText className="productCardText">
                                <span
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "16px",
                                    marginRight: "5px"
                                  }}
                                >
                                  Ingredients:
                                </span>

                                {product.ingredents &&
                                product.ingredents.length > 0
                                  ? this.getIngredientDetails(
                                      product.ingredents
                                    )
                                  : ""}
                              </CardText>
                            ) : (
                              ""
                            )}

                            <Button
                              className="addToCartForTable"
                              onClick={() => {
                                this.setState({
                                  currentProductToPass: product
                                });
                                this.setState({ isVisible: true });
                              }}
                              // onClick={() =>
                              //   //  this.props.dispatch(toggleModal(product))
                              //   this.toggle(
                              //     product,
                              //     this.state.currentsuperCategory,
                              //     this.state.currentcatType
                              //   )
                              // }
                            >
                              ADD
                            </Button>
                          </CardBody>
                        </Card>
                      </div>
                    </div>
                  </Col>
                );
              })}
            {!prodsData ||
              (prodsData.length == 0 && !this.props.getProductsLoading && (
                <div style={{ width: "100%", textAlign: "center" }}>
                  Sorry this item's' not available.
                </div>
              ))}
          </Row>

          <Modal isOpen={modal} toggle={this.onlytoggleModal}>
            <ModalBody style={{ padding: 0 }}>
              <div>
                <img
                  src={`${globalConst.s3url}${editingProduct.image}`}
                  width="100%"
                  height="200px"
                  className="modalImage"
                />

                <div
                  style={{
                    margin: "0 1rem",
                    height: "60px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <h5 style={{ textTransform: "capitalize" }}>
                    {editingProduct.name}
                  </h5>
                  <p style={{ color: "muted", fontWeight: "600" }}>
                    Pris : Kr. {editingProduct.price}
                  </p>
                </div>
              </div>
            </ModalBody>
            <ModalFooter style={{ flexWrap: "nowrap" }}>
              <div className="col-md-4 col-xs-2">
                <div className="add_product">
                  <Button
                    onClick={this.decreaseItem}
                    outline
                    color="secondary"
                    className="minus_btn"
                  >
                    -
                  </Button>
                  <input
                    className="form-control"
                    type="text"
                    value={quantity}
                    onChange={(e) => this.handleChange(e)}
                  />
                  <Button
                    onClick={this.incrementItem}
                    outline
                    color="secondary"
                    className="add_btn"
                  >
                    +
                  </Button>
                </div>
              </div>

              <div className="col-md-8 col-xs-6">
                <Button
                  color="danger"
                  onClick={() => (quantity > 0 ? this.addToCart() : "")}
                  className="btn-block"
                >
                  Add Kr. {editingProduct.price * quantity + extraPrice}
                </Button>{" "}
              </div>
            </ModalFooter>
          </Modal>
          {this.state.isVisible && (
            <NewOrderNowCom
              isVisible={this.state.isVisible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              product={this.state.currentProductToPass}
              superCategory={this.state.currentsuperCategory}
            />
          )}
        </Container>
      );
    }
  }
}

const mapStateToProps = ({ products, restaurant, order, cartReducer }) => {
  const { items: itemsInCart } = cartReducer;
  console.log("items in cart", itemsInCart);
  const driveoutCharge = restaurant.driveoutCharge;
  const {
    availability,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday,
    additional,
    categoryBackColor,
    tidypaypermission,
    colors,
    logo
  } = restaurant;

  const resId = restaurant.id;

  const {
    activeTab,
    items,
    loading,
    error,
    cart,
    totalQuantity,
    totalPrice,
    modal,
    editingProduct,
    quantity,
    popup,
    isOpen,
    instruction,
    getCategoriesLoading,
    getCategoriesSuccess,
    getCategoriesError,
    categoriesData,
    getProductsLoading,
    getProductsSuccess,
    getProductsError,
    productsData,
    extra,
    getExtraItemLoading,
    getExtraItemSuccess,
    getExtraItemError,
    addExtraPrice,
    extraPrice,
    extraItems
  } = products;
  const {
    supercategoryLoading,
    supercategorySuccess,
    supercategoryData,
    userChecked,
    userId,
    activeId,
    orderType,
    paymentHittingLoading,
    paymentHittingSuccess,
    paymentHittingError,
    paymentHittingData,
    orderSuccess,
    orderData
  } = order;
  return {
    userChecked,
    userId,
    activeId,
    orderType,
    paymentHittingLoading,
    paymentHittingSuccess,
    paymentHittingError,
    paymentHittingData,
    orderSuccess,
    orderData,
    activeTab,
    items,
    loading,
    error,
    cart,
    totalQuantity,
    totalPrice,
    modal,
    resId,
    editingProduct,
    quantity,
    popup,
    isOpen,
    driveoutCharge,
    instruction,
    getCategoriesLoading,
    getCategoriesSuccess,
    getCategoriesError,
    categoriesData,
    getProductsLoading,
    getProductsSuccess,
    getProductsError,
    productsData,
    availability,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday,
    additional,
    categoryBackColor,
    tidypaypermission,
    colors,
    supercategoryLoading,
    supercategorySuccess,
    supercategoryData,
    extra,
    extraPrice,
    extraItems,
    logo,
    itemsInCart
  };
};

export default connect(mapStateToProps)(PublicTablicMenu);