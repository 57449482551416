import React, { Component } from "react"; // importing main react
import { isMobile } from "react-device-detect";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
  Col,
  Alert,
} from "reactstrap";
import "../components/font.css";
import ModalCart from "./../components/ModalCart";
import warning from "../assets/Something went wrong.jpg";
import { FaShoppingCart } from "react-icons/fa";
import reload from "../assets/reload.png";
import { SpinnerRoundFilled } from "spinners-react";
import moment from "moment";
import { connect } from "react-redux";
import {
  fetchProducts,
  toggleModal,
  onlytoggleModal,
  addQuantity,
  removeQuantity,
  addItems,
  getCart,
  removeFromCart,
  removeExtraFromCart,
  handleQuantityChange,
  deliveryOptionsPopup,
  getRestaurantInfo,
  resetOrderPage,
  resetModal,
  handleInstructionChange,
  getsupercategory,
  getExtraItems,
  clearStorage,
  get_categories,
  get_products,
  addExtraPrice,
  reduceExtraPrice,
  addExtraItems,
  reduceExtraItems,
  handleExtraInstructions,
  clearPaymentData
} from "../actions";
import classnames from "classnames";
import globalConst from "../helpers/global";
import Cart from "./Cart";
import Header from "./Header";
import { grey } from "color-name";
import { getTaxModule } from "../helpers/taxmodule";

import { socket } from "../helpers/globSocket";
// import WindowSize from "./WindowSize";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weekday: "",
      currentTime: moment().hour(),
      restaurantClosed: true,
      toggleCart: false,
      prodsData: [],
      currentsuperCategory: "",
      currentcatType: {},
      currentcategoryImage: "",
      currentCategoryName: "",
      checAvFlag: false,
      tryagainCount: 1,
      daySchedule: {},
      currentProduct: {},
      currentProductLoading: false,
      currentPram: this.props.match.params.id,
      categoryBackColor: "",
      currentColor: "",
      incrementChecked: [],
      ingredentsDescription: "",
      ingredentsDesCArray: [],
      incrementCheckedIds: [],
      extraItems: [],
      checkedExtra: [],
      extraItemsOpen: [],
      checkedInstruction: "",
      showCartModal: true,
      toggleModal: false,
      descriptionIngredient: "",
      instructionExtra: "",
      ingStockProductName: []
      // stockNameIngredents: []
    };

    this.props.dispatch(clearStorage());
  }

  clickedExtraInstructions = (value) => {
    this.setState({ checkedInstruction: value });
    this.props.dispatch(handleExtraInstructions(value));
  };
  async localTime() {
    var localSchedule = await localStorage.getItem("daySchedule");
    return localSchedule;
  }

  showCartModal = () => {
    this.setState({ showCartModal: true });
    // this.setState({ toggleModal: !this.state.toggleModal });
  };

  handleModalClose = () => {
    this.setState({ showCartModal: false });
  };

  componentDidMount = async () => {
    const restaurantId = this.props.match.params.id;
    this.props.dispatch(get_categories(restaurantId));
    this.props.dispatch(resetOrderPage());
    this.props.dispatch(resetModal());
    this.props.dispatch(getCart());
    this.props.dispatch(fetchProducts(restaurantId));
    this.props.dispatch(getRestaurantInfo(restaurantId));
    // this.props.dispatch(getExtraItems(restaurantId));
    this.props.dispatch(clearPaymentData());
    socket.on("restAvailability", () => {
      this.props.dispatch(getRestaurantInfo(restaurantId));
    });
    socket.on("weekAvailability", async (sdt) => {
      setTimeout(() => {
        if (this.state.daySchedule !== undefined) {
          if (sdt.socId) {
            this.props.dispatch(getRestaurantInfo(restaurantId));
          }

          if (sdt.slug == restaurantId) {
            var day = moment().day();
            var optime = "";
            var clostime = "";
            var localopTime =
              this.state.daySchedule && this.state.daySchedule.openingTime;
            // JSON.parse(localweek).openingTime;
            var localclTime =
              this.state.daySchedule && this.state.daySchedule.closingTime;

            localopTime = localopTime.split(":");
            localclTime = localclTime.split(":");
            if (day === 0) {
              optime = sdt.sunday.openingTime;
              clostime = sdt.sunday.closingTime;
            } else if (day === 1) {
              optime = sdt.monday.openingTime;
              clostime = sdt.monday.closingTime;
            } else if (day === 2) {
              optime = sdt.tuesday.openingTime;
              clostime = sdt.tuesday.closingTime;
            } else if (day === 3) {
              optime = sdt.wednesday.openingTime;
              clostime = sdt.wednesday.closingTime;
            } else if (day === 4) {
              optime = sdt.thursday.openingTime;
              clostime = sdt.thursday.closingTime;
            } else if (day === 5) {
              optime = sdt.friday.openingTime;
              clostime = sdt.friday.closingTime;
            } else if (day === 6) {
              optime = sdt.saturday.openingTime;
              clostime = sdt.saturday.closingTime;
            }

            if (optime !== "" && clostime !== "") {
              optime = optime.split(":");
              clostime = clostime.split(":");
              if (
                (Number(optime[0]) + Number(optime[1])) * 60 !==
                (Number(localopTime[0]) + Number(localopTime[1])) * 60 ||
                (Number(clostime[0]) + Number(clostime[1])) * 60 !==
                (Number(localclTime[0]) + Number(localclTime[1])) * 60
              ) {
                this.props.dispatch(getRestaurantInfo(restaurantId));
                this.checkWeekAvailability();
                // socket.off("weekAvailability");
                window.location.reload();
              } else {
              }
            }
          }
        }
      }, 4000);
    });
  };
  async componentDidUpdate(prevProps, prevState) {
    let day = moment().day();
    if (prevProps.loading !== this.props.loading) {
      this.setState({ categoryBackColor: this.props.categoryBackColor });
      localStorage.setItem("currentDayNumber", day);
      if (day == 1) {
        this.setState({ currentDay: "monday" });
        this.setState({ daySchedule: this.props.monday });
      } else if (day == 2) {
        this.setState({ currentDay: "tuesday" });
        this.setState({ daySchedule: this.props.tuesday });
      } else if (day === 3) {
        this.setState({ currentDay: "wednesday" });
        this.setState({ daySchedule: this.props.wednesday });
      } else if (day === 4) {
        this.setState({ currentDay: "thursday" });
        this.setState({ daySchedule: this.props.thursday });
      } else if (day === 5) {
        this.setState({ currentDay: "friday" });
        this.setState({ daySchedule: this.props.friday });
      } else if (day === 6) {
        this.setState({ currentDay: "saturday" });
        this.setState({ daySchedule: this.props.saturday });
      } else {
        this.setState({ currentDay: "sunday" });
        this.setState({ daySchedule: this.props.sunday });
      }
    }

    if (prevProps.getCategoriesSuccess !== this.props.getCategoriesSuccess) {
      let dt = await this.props.categoriesData;
      let id = dt && dt.length ? dt[0]._id : "";
      if (id) {
        this.getProds(dt[0]);
      }
    }

    if (prevProps.getProductsSuccess !== this.props.getProductsSuccess) {
      let dt = await this.props.productsData;
      if (dt.length) {
        await this.setState({
          prodsData: this.props.productsData,
          currentProduct: this.props.productsData[0]
        });
      } else {
        await this.setState({
          prodsData: [],
          currentProduct: ""
        });
      }
    }
  }

  toggleCartFunc = (value) => {
    window.scrollTo(0, 0);
    this.setState({ toggleCart: !value });
  };
  // dspth = async restaurantId => {
  //   var { dispatch } = this.props;
  //   await dispatch(getRestaurantInfo(restaurantId));
  //   this.checkWeekAvailability();
  // };
  checkWeekAvailability = async () => {
    // window.location.reload();
    // alert("GOT IN");
    this.setState({ checAvFlag: true, tryagainCount: 0 });
    var day = moment().day();
    if (day === 1) {
      if (this.props.monday && this.props.monday.status === true) {
        let optime =
          this.props.monday && this.props.monday.openingTime
            ? this.props.monday.openingTime
            : "00:00";
        optime = optime.split(":");
        let clostime =
          this.props.monday && this.props.monday.closingTime
            ? this.props.monday.closingTime
            : "00:00";
        clostime = clostime.split(":");
        if (
          this.props.monday &&
          this.props.monday.status === true &&
          Number(optime[0]) <= Number(moment().hour()) &&
          Number(clostime[0]) >= Number(moment().hour())
        ) {
          // opening time
          if (Number(optime[0]) === Number(moment().hour())) {
            if (Number(optime[1] <= Number(moment().minutes()))) {
              await this.setState({ restaurantClosed: false });
            } else {
              await this.setState({ restaurantClosed: true });
            }
          } else {
            await this.setState({ restaurantClosed: false });
          }
          // close time 17:30 -  17:25
          if (Number(clostime[0]) === Number(moment().hour())) {
            if (Number(clostime[1]) - Number(moment().minutes()) > 0) {
              await this.setState({ restaurantClosed: false });
            } else {
              await this.setState({ restaurantClosed: true });
            }
          }
        }
      } else {
        await this.setState({ restaurantClosed: true });
      }
    } else if (day === 2) {
      //  =========== START OF DAY TWO ==============
      if (this.props.tuesday && this.props.tuesday.status === true) {
        let optime =
          this.props.tuesday && this.props.tuesday.openingTime
            ? this.props.tuesday.openingTime
            : "00:00";
        optime = optime.split(":");
        let clostime =
          this.props.tuesday && this.props.tuesday.closingTime
            ? this.props.tuesday.closingTime
            : "00:00";
        clostime = clostime.split(":");
        if (
          this.props.tuesday &&
          this.props.tuesday.status === true &&
          Number(optime[0]) <= Number(moment().hour()) &&
          Number(clostime[0]) >= Number(moment().hour())
        ) {
          // opening time
          if (Number(optime[0]) === Number(moment().hour())) {
            if (Number(optime[1] <= Number(moment().minutes()))) {
              await this.setState({ restaurantClosed: false });
            } else {
              await this.setState({ restaurantClosed: true });
            }
          } else {
            await this.setState({ restaurantClosed: false });
          }
          // close time 17:30 -  17:25
          if (Number(clostime[0]) === Number(moment().hour())) {
            if (Number(clostime[1]) - Number(moment().minutes()) > 0) {
              await this.setState({ restaurantClosed: false });
            } else {
              await this.setState({ restaurantClosed: true });
            }
          }
        }
      } else {
        await this.setState({ restaurantClosed: true });
      }
    } else if (day === 3) {
      //  =========== START OF DAY THREE ==============
      if (this.props.wednesday && this.props.wednesday.status === true) {
        let optime =
          this.props.wednesday && this.props.wednesday.openingTime
            ? this.props.wednesday.openingTime
            : "00:00";
        optime = optime.split(":");
        let clostime =
          this.props.wednesday && this.props.wednesday.closingTime
            ? this.props.wednesday.closingTime
            : "00:00";
        clostime = clostime.split(":");
        if (
          this.props.wednesday &&
          this.props.wednesday.status === true &&
          Number(optime[0]) <= Number(moment().hour()) &&
          Number(clostime[0]) >= Number(moment().hour())
        ) {
          // opening time
          if (Number(optime[0]) === Number(moment().hour())) {
            if (Number(optime[1] <= Number(moment().minutes()))) {
              await this.setState({
                restaurantClosed: false,
                checAvFlag: false
              });
            } else {
              await this.setState({
                restaurantClosed: true,
                checAvFlag: false
              });
            }
          } else {
            await this.setState({ restaurantClosed: false, checAvFlag: false });
          }
          // close time 17:30 -  17:25
          if (Number(clostime[0]) === Number(moment().hour())) {
            if (Number(clostime[1]) - Number(moment().minutes()) > 0) {
              await this.setState({
                restaurantClosed: false,
                checAvFlag: false
              });
            } else {
              await this.setState({
                restaurantClosed: true,
                checAvFlag: false
              });
            }
          }
        }
      } else {
        await this.setState({ restaurantClosed: true });
      }
    } else if (day === 4) {
      //  =========== START OF DAY FOUR ==============
      if (this.props.thursday && this.props.thursday.status === true) {
        let optime =
          this.props.thursday && this.props.thursday.openingTime
            ? this.props.thursday.openingTime
            : "00:00";
        optime = optime.split(":");
        let clostime =
          this.props.thursday && this.props.thursday.closingTime
            ? this.props.thursday.closingTime
            : "00:00";
        clostime = clostime.split(":");
        if (
          this.props.thursday &&
          this.props.thursday.status === true &&
          Number(optime[0]) <= Number(moment().hour()) &&
          Number(clostime[0]) >= Number(moment().hour())
        ) {
          // opening time
          if (Number(optime[0]) == Number(moment().hour())) {
            if (Number(optime[1] <= Number(moment().minutes()))) {
              await this.setState({ restaurantClosed: false });
            } else {
              await this.setState({ restaurantClosed: true });
            }
          } else {
            await this.setState({ restaurantClosed: false });
          }
          // close time 17:30 -  17:25
          if (Number(clostime[0]) === Number(moment().hour())) {
            if (Number(clostime[1]) - Number(moment().minutes()) > 0) {
              await this.setState({ restaurantClosed: false });
            } else {
              await this.setState({ restaurantClosed: true });
            }
          }
        }
      } else {
        await this.setState({ restaurantClosed: true });
      }
    } else if (day === 5) {
      //  =========== START OF DAY FIVE ==============
      if (this.props.friday && this.props.friday.status === true) {
        let optime =
          this.props.friday && this.props.friday.openingTime
            ? this.props.friday.openingTime
            : "00:00";
        optime = optime.split(":");
        let clostime =
          this.props.friday && this.props.friday.closingTime
            ? this.props.friday.closingTime
            : "00:00";
        clostime = clostime.split(":");
        if (
          this.props.friday &&
          this.props.friday.status === true &&
          Number(optime[0]) <= Number(moment().hour()) &&
          Number(clostime[0]) >= Number(moment().hour())
        ) {
          // opening time
          if (Number(optime[0]) === Number(moment().hour())) {
            if (Number(optime[1] <= Number(moment().minutes()))) {
              await this.setState({ restaurantClosed: false });
            } else {
              await this.setState({ restaurantClosed: true });
            }
          } else {
            await this.setState({ restaurantClosed: false });
          }
          // close time 17:30 -  17:25
          if (Number(clostime[0]) === Number(moment().hour())) {
            if (Number(clostime[1]) - Number(moment().minutes()) > 0) {
              await this.setState({ restaurantClosed: false });
            } else {
              await this.setState({ restaurantClosed: true });
            }
          }
        }
      } else {
        await this.setState({ restaurantClosed: true });
      }
    } else if (day === 6) {
      //  =========== START OF DAY SIX ==============
      if (this.props.saturday && this.props.saturday.status === true) {
        let optime =
          this.props.saturday && this.props.saturday.openingTime
            ? this.props.saturday.openingTime
            : "00:00";
        optime = optime.split(":");
        let clostime =
          this.props.saturday && this.props.saturday.closingTime
            ? this.props.saturday.closingTime
            : "00:00";
        clostime = clostime.split(":");
        if (
          this.props.saturday &&
          this.props.saturday.status === true &&
          Number(optime[0]) <= Number(moment().hour()) &&
          Number(clostime[0]) >= Number(moment().hour())
        ) {
          // opening time
          if (Number(optime[0]) === Number(moment().hour())) {
            if (Number(optime[1] <= Number(moment().minutes()))) {
              await this.setState({ restaurantClosed: false });
            } else {
              await this.setState({ restaurantClosed: true });
            }
          } else {
            await this.setState({ restaurantClosed: false });
          }
          // close time 17:30 -  17:25
          if (Number(clostime[0]) === Number(moment().hour())) {
            if (Number(clostime[1]) - Number(moment().minutes()) > 0) {
              await this.setState({ restaurantClosed: false });
            } else {
              await this.setState({ restaurantClosed: true });
            }
          }
        }
      } else {
        await this.setState({ restaurantClosed: true });
      }
    } else if (day === 0) {
      //  =========== START OF DAY SEVEN ==============
      if (this.props.sunday && this.props.sunday.status === true) {
        let optime =
          this.props.sunday && this.props.sunday.openingTime
            ? this.props.sunday.openingTime
            : "00:00";

        optime = optime.split(":");
        let clostime =
          this.props.sunday && this.props.sunday.closingTime
            ? this.props.sunday.closingTime
            : "00:00";
        clostime = clostime.split(":");
        if (
          this.props.sunday &&
          this.props.sunday.status === true &&
          Number(optime[0]) <= Number(moment().hour()) &&
          Number(clostime[0]) >= Number(moment().hour())
        ) {
          // opening time
          if (Number(optime[0]) === Number(moment().hour())) {
            if (Number(optime[1] <= Number(moment().minutes()))) {
              await this.setState({ restaurantClosed: false });
            } else {
              await this.setState({ restaurantClosed: true });
            }
          } else {
            await this.setState({ restaurantClosed: false });
          }
          // close time 17:30 -  17:25
          if (Number(clostime[0]) === Number(moment().hour())) {
            if (Number(clostime[1]) - Number(moment().minutes()) > 0) {
              await this.setState({ restaurantClosed: false });
            } else {
              await this.setState({ restaurantClosed: true });
            }
          }
        }
      } else {
        await this.setState({ restaurantClosed: true });
      }
    }
  };

  onlytoggleModal = () => {
    this.props.dispatch(onlytoggleModal());
  };

  toggle = (product, spcat, catType) => {
    this.setState({ checkedInstruction: "" });
    this.props.dispatch(getsupercategory(spcat));
    var dt = this.props.supercategoryData;
    var prod = {
      _id: product._id,
      isDeleted: product.isDeleted,
      employeeId: product.employeeId,
      categoryId: product.categoryId,
      branchId: product.branchId,
      image: product.image,
      name: product.name,
      price: product.price,
      itemNo: product.itemNo,
      __v: 0,
      quantity: product.quantity,
      totalPrice: product.totalPrice,
      instruction: product.instruction,
      superCategory: spcat,
      catType: catType,
      qtyTrack: [],
      ingredents: product.ingredents ? product.ingredents : [],
      status: "IN-PROCESS"
    };
    fetch(`${globalConst.serverip}public/getsupercat/${spcat}`)
      .then((res) => res.json())
      .then((json) => {
        var superCatData = json.data;
        prod.sCatType = superCatData.sCatType;
        this.props.dispatch(toggleModal(prod));
        this.setState({
          incrementChecked: []
        });
        let ing = product.ingredents ? product.ingredents : [];
        for (let i = 0; i < ing.length; i++) {
          let chek = [...this.state.incrementChecked];
          chek.push(true);
          this.setState({
            incrementChecked: chek,
            ingredentsDesCArray: [],
            incrementCheckedIds: [],
            ingredentsDescription: ""
          });
        }
      })
      .catch((error) => {

      });
    const { extra } = this.props;
    this.state.checkedExtra = [];
    this.state.extraItemsOpen = [];
    let chekEx = [];
    let isOpen = [];
    for (let [idx, ext] of extra.entries()) {
      chekEx.push([]);
      isOpen.push(false);
      for (let i = 0; i < ext.items.length; i++) {
        // let chekEx = this.state.checkedExtra
        //   ? [...this.state.checkedExtra]
        //   : [];
        chekEx[idx].push(false);
        // this.state.checkedExtra = chekEx;
      }
    }
    this.state.checkedExtra = chekEx;
    this.state.extraItemsOpen = isOpen;
  };

  incrementItem = () => {
    this.props.dispatch(addQuantity());
  };

  decreaseItem = () => {
    this.props.dispatch(removeQuantity());
  };

  addToCart = () => {
    this.props.dispatch(addItems());
  };

  removeItemFromCart = (id) => {
    this.props.dispatch(removeFromCart(id));
  };
  removeExtraFromCart = (proId, extraId, ix) => {
    this.props.dispatch(removeExtraFromCart({ proId, extraId, ix }));
  };

  handleChange = (e) => {
    const value = Number(e.target.value);
    this.props.dispatch(handleQuantityChange(value));
  };

  openDeliveryOptions = (type) => {
    this.props.dispatch(deliveryOptionsPopup(type));
  };

  handleInstruction = (e) => {
    let descArray = this.state.descriptionIngredient.split(",");
    this.setState({ instructionExtra: e.target.value });

    let eValueArray = this.state.instructionExtra.split(",");
    let newArray = [];
    for (let i = 0; i < eValueArray.length; i++) {
      let z = 0;

      for (let j = 0; j < descArray.length; j++) {
        if (eValueArray[i] == descArray[j]) {
          z++;
        }
      }

      if (z == 0) {
        newArray.push(eValueArray[i]);
      }
    }

    let stringArray = newArray.join(",");

    this.props.dispatch(handleInstructionChange(e.target.value));
    this.setState({ instructionExtra: "" });
    // this.props.dispatch(handleInstructionChange(stringArray));
  };
  handleExtraOpen = (id) => {
    const { extraItemsOpen } = this.state;
    extraItemsOpen[id] = !extraItemsOpen[id];
    this.setState({ extraItemsOpen: extraItemsOpen });
  };
  handleAddExtraItems = async (e, ix, idx, exItem) => {
    const checkItm = [...this.state.checkedExtra];
    let itm = e.target.value;
    let isChk = !checkItm[ix][idx];
    checkItm[ix][idx] = isChk;
    if (isChk) {
      await this.props.dispatch(addExtraPrice(Number(exItem.price)));
      await this.props.dispatch(addExtraItems(exItem));
    } else {
      await this.props.dispatch(reduceExtraPrice(Number(exItem.price)));
      await this.props.dispatch(reduceExtraItems(exItem));
    }
    this.setState({
      checkedExtra: checkItm
    });
  };
  handleInstructionText = async (e, idx, id, ing, stockNameIngredents) => {
    let chek = [...this.state.incrementChecked];

    chek[idx] = !chek[idx];
    this.setState({
      incrementChecked: chek
    });
    let deskArray = [...this.state.ingredentsDesCArray];
    let CheckIds = [...this.state.incrementCheckedIds];
    let ind = `Uten ${e.target.value}`;
    let arr = [];
    let ids = [];

    if (await !chek[idx]) {
      deskArray.push(ind);
      CheckIds.push(id);
      arr = deskArray;
      ids = CheckIds;
    } else {
      arr = deskArray.filter((val) => {
        return ind != val;
      });
      ids = CheckIds.filter((val) => {
        return id != val;
      });
    }
    let desc = arr.length > 0 ? arr.join(", ") : "";
    desc = `${desc},`;
    if (desc == ",") {
      desc = "";
    }
    this.setState({ descriptionIngredient: desc });

    //new array with
    let eValueArray = this.state.instructionExtra.split(",");

    // let stockNameIngredentsArray = stockNameIngredents.split(",");
    let newArray = [];
    for (let i = 0; i < eValueArray.length; i++) {
      let z = 0;

      for (let j = 0; j < stockNameIngredents.length; j++) {
        if (
          eValueArray[i] == `Uten ${stockNameIngredents[j]}` ||
          eValueArray[i] == `Uten ${stockNameIngredents[j]}`
        ) {
          z++;
        }
      }

      if (z == 0) {
        if (eValueArray[i] != "") {
          eValueArray[i] = eValueArray[i].trim();
          newArray.push(eValueArray[i]);
        }
      }
    }

    //new array end
    // desc = instructionSend;
    // this.props.dispatch(handleInstructionChange({ instructionSend, ids }));
    let stringArray = newArray.join(",");

    desc = stringArray + "," + desc;
    desc = desc.replace(/^,/, "");
    desc = desc.replace(/,\s*$/, ""); //regex to remove trailing comma
    let descWithoutSpaceArray = desc.split(",");
    let descWithoutSpace = descWithoutSpaceArray.map((itm) => {
      return itm.trim();
    });
    let descString = descWithoutSpace.join(",");
    desc = descString;
    this.props.dispatch(handleInstructionChange({ desc, ids }));
    await this.setState({
      ingredentsDesCArray: arr,
      ingredentsDescription: desc,
      incrementCheckedIds: ids
    });
  };
  getProds = (itm) => {
    this.setState({
      currentCategoryName: itm.name,
      currentsuperCategory: itm.superCategory,
      currentcatType: itm.catType,
      currentcategoryImage: itm.image
    });
    var { dispatch } = this.props;
    dispatch(get_products(itm._id));
    this.props.dispatch(getExtraItems(itm.superCategory));
  };
  currentProductTopShow = async (prod) => {
    this.setState({ currentProductLoading: true });
    window.scrollTo(0, 0);
    await this.setState({ currentProduct: prod });
    this.setState({ currentProductLoading: false });
  };
  getIngedentDeatails = (ingredents) => {
    if (ingredents && ingredents.length > 0) {
      let ingList = "";
      for (let item of ingredents) {
        ingList = ingList.concat(
          `${item && item.stockproduct && item.stockproduct.name
            ? item.stockproduct.name
            : ""
          }, `
        );
      }
      return ingList.replace(/,\s*$/, "");
    }
  };
  render() {
    const { prodsData } = this.state;
    const restLogo = JSON.parse(localStorage.getItem("state")).restaurant.logo;
    const {
      loading,
      items,
      cart,
      // activeTab,
      totalQuantity,
      totalPrice,
      modal,
      editingProduct,
      quantity,
      isOpen,
      popup,
      history,
      error,
      driveoutCharge,
      extraPrice,
      instruction
    } = this.props;
    let stockNameIngredents = [];
    if (editingProduct && editingProduct.ingredents.length > 0) {
      editingProduct.ingredents.map((ing, index) => {
        stockNameIngredents.push(ing.stockproduct.name);
        // this.setState({
        //   stockNameIngredents: stockNameIngredents.push(ing.stockproduct.name)
        // });
      });
    }
    if (loading) {
      return (
        // <div>Loading</div>
        <div
          style={{
            width: "100℅",
            height: "100vh",
            textAlign: "center",
            // display: "flex",
            // alignItem: "center",
            justifyContent: "center"
          }}
        >
          <SpinnerRoundFilled
            size={50}
            thickness={100}
            speed={100}
            color="#36ad47"
          />
        </div>
      );
    } else if (error) {
      return (
        <div
          style={{
            width: "50℅",
            height: "100vh",
            // textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            alignContent: "center",
            alignSelf: "center",
            justifyContent: "center"
          }}
        >
          <img src={warning} style={{ height: "65%", width: "50%" }} />
          <Button
            onClick={() => {
              localStorage.clear();

              window.location.reload();
            }}
            style={{
              borderRadius: 20,
              width: "30%",
              color: "white",
              fontWeight: "bold"
            }}
            color="warning"
          >
            Try again
            <img src={reload} style={{ height: 20, widht: 20 }} />
          </Button>
        </div>
      );
    } else {
      if (Number(this.state.tryagainCount) !== 0) {
        setTimeout(() => {
          this.checkWeekAvailability();
        }, 2500);
      }
      return (
        <div className="menu-css">
          <div
            className="submenu-css"
            style={
              (this.props.availability && !this.props.availability.status) ||
                this.state.restaurantClosed === true
                ? {
                  // overflowY: "hidden",
                  overflowX: "hidden",
                  height: "100vh"
                }
                : {
                  // color: this.props.colors.orderNowTextColor.toString(),
                  // background: this.props.colors.orderNowBtnBackground.toString(),
                  paddingBottom: ""
                }
            }
          >
            {(this.props.availability &&
              this.props.availability.status === false) ||
              this.state.restaurantClosed === true ? (
              <Alert
                color="warning"
                className="alertText"
                style={{ textAlign: "center", marginTop: "55px" }}
              >
                {
                  <p className="alertTitle">
                    <marquee style={{}}>
                      Nettbestilling er for øyeblikket ikke tilgjengelig. Du kan
                      se hele menyen, men du kan ikke legge inn bestillingen.
                    </marquee>
                  </p>
                }
                {/* Nettbestilling er for øyeblikket ikke tilgjengelig. Du kan se
                hele menyen, men ikke foreta bestillingen online */}
              </Alert>
            ) : (
              ""
            )}
            {/* Start Restaurant on */}
            {this.props.availability &&
              this.props.availability.status === true &&
              this.state.restaurantClosed === false ? (
              <Alert
                color="warning"
                className="alertText"
                id="restaurantOnText"
                style={{ textAlign: "center", marginTop: "55px" }}
              >
                {
                  <p className="alertTitle">
                    <marquee style={{ marginTop: "6px" }}>
                      VELKOMMEN!! Bestill og nyt favorittmaten din. Takk for
                      besøket.
                    </marquee>
                  </p>
                }
                {/* Nettbestilling er for øyeblikket ikke tilgjengelig. Du kan se
                hele menyen, men ikke foreta bestillingen online */}
              </Alert>
            ) : (
              ""
            )}
            {/* End Restaurant on */}
            {/* Start Disabled button on mobile view */}
            {this.props.availability ? (
              <Modal
                isOpen={(this.state.restaurantClosed || !this.props.availability.status) ? modal : false}
                toggle={this.onlytoggleModal}
              >
                <ModalHeader toggle={this.onlytoggleModal}>
                  {editingProduct.name}
                  {/* <br /> */}
                  <span style={{ color: "grey", fontSize: "15px" }}>
                    Pris : Kr. {editingProduct.price}
                  </span>
                  {/* {console.log("ThisIsATestingConsoleCommentForTestingPurposeToCheckBuild!!")} */}
                </ModalHeader>
                <ModalBody>
                  <div className="row">
                    <div className="col-xs-2 col-md-4">
                      <img
                        src={`${globalConst.s3url}${editingProduct.image}`}
                        alt={editingProduct.name}
                        style={{
                          maxWidth: "100%",
                          height: "100px",
                          alignSelf: "center",
                          verticalAlign: "center"
                          // marginLeft: "20%"
                        }}
                      />
                    </div>
                    <div className="col-xs-6 col-md-4">
                      <div className="row">
                        <div className="col-lg-12">
                          <h6>Ingredienser</h6>
                          <ul className="checkboxList">
                            {editingProduct &&
                              editingProduct.ingredents &&
                              editingProduct.ingredents.map((ing, index) => {
                                return (
                                  <li key={index} className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      onChange={(e) =>
                                        this.handleInstructionText(
                                          e,
                                          index,
                                          ing._id,
                                          ing,
                                          stockNameIngredents
                                        )
                                      }
                                      value={ing.stockproduct.name}
                                      checked={
                                        this.state.incrementChecked[index]
                                          ? this.state.incrementChecked[index]
                                          : false
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexCheckDefault"
                                    >
                                      {ing.stockproduct.name}
                                    </label>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-6 col-md-4">
                      <div className="row">
                        <div className="col-lg-12">
                          {this.state.currentProduct &&
                            this.state.currentProduct.isExtraInformation && (
                              <>
                                <h6>Spiciness</h6>
                                <ul className="checkboxList cardHover">
                                  <li className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      onChange={() =>
                                        this.clickedExtraInstructions("Mild")
                                      }
                                      value="Mild"
                                      checked={
                                        this.state.checkedInstruction === "Mild"
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexCheckDefault"
                                    >
                                      {"Mild"}
                                    </label>
                                  </li>
                                  <li className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      onChange={(e) =>
                                        this.clickedExtraInstructions("Spicy")
                                      }
                                      value="Spicy"
                                      checked={
                                        this.state.checkedInstruction ===
                                        "Spicy"
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexCheckDefault"
                                    >
                                      {"Spicy"}
                                    </label>
                                  </li>
                                  <li className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      onChange={() =>
                                        this.clickedExtraInstructions(
                                          "ExtraSpicy"
                                        )
                                      }
                                      value="ExtraSpicy"
                                      checked={
                                        this.state.checkedInstruction ===
                                        "ExtraSpicy"
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexCheckDefault"
                                    >
                                      {"ExtraSpicy"}
                                    </label>
                                    <p
                                      style={{
                                        color:
                                          this.props.colors &&
                                          this.props.colors.ingredentTextColor
                                      }}
                                    >


                                    </p>
                                  </li>
                                </ul>
                              </>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row" style={{ display: "none" }}>
                    <div className="col-lg-12">
                      <h6>Legg til kommentar (valgfritt):</h6>
                      <textarea
                        name="instruction"
                        onChange={(e) => this.handleInstruction(e)}
                        value={instruction}
                        style={{
                          width: "80%",
                          borderWidth: 1
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      {this.props.extra
                        ? this.props.extra.map((extra, ix) => {
                          return (
                            <div className="row" key={ix}>
                              <div className="col-lg-12">
                                <div className="text-left">
                                  <a
                                    onClick={() => this.handleExtraOpen(ix)}
                                    aria-expanded={
                                      this.state.extraItemsOpen[ix]
                                    }
                                    aria-controls="example-collapse-text"
                                    className="sm-btn"
                                    href="#"
                                  >
                                    {`+ ${extra.name}`}
                                  </a>
                                </div>
                                {this.state.extraItemsOpen &&
                                  this.state.extraItemsOpen[ix] ? (
                                  <ul className="list-group extraList">
                                    {extra &&
                                      extra.items.map((item, idx) => {
                                        return (
                                          <li
                                            className="list-group-item"
                                            key={idx}
                                          >
                                            <div className="form-check">
                                              <label className="form-check-label">
                                                <input
                                                  onChange={(e) =>
                                                    this.handleAddExtraItems(
                                                      e,
                                                      ix,
                                                      idx,
                                                      item
                                                    )
                                                  }
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  value={item._id}
                                                  checked={
                                                    this.state.checkedExtra[
                                                    ix
                                                    ][idx]
                                                  }
                                                />{" "}
                                                <img
                                                  style={{
                                                    width: "35px",
                                                    height: "35px"
                                                  }}
                                                  src={`${globalConst.s3url}${item.image
                                                    }`}
                                                  width="10%"
                                                />{" "}
                                                {`${item.name} (kr. ${item.price
                                                  })`}
                                              </label>
                                            </div>
                                          </li>
                                        );
                                      })}
                                  </ul>
                                ) : null}
                              </div>
                            </div>
                          );
                        })
                        : ""}
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter style={{ flexWrap: "nowrap", display: "none" }}>
                  <div className="col-md-4 col-xs-2">
                    <div className="add_product">
                      <Button
                        onClick={this.decreaseItem}
                        outline
                        color="secondary"
                        className="minus_btn"
                      >
                        -
                      </Button>
                      <input
                        className="form-control"
                        type="text"
                        value={quantity}
                        onChange={(e) => this.handleChange(e)}
                      />
                      <Button
                        onClick={this.incrementItem}
                        outline
                        color="secondary"
                        className="add_btn"
                      >
                        +
                      </Button>
                    </div>
                  </div>

                  <div className="col-md-8 col-xs-6">
                    <Button
                      disabled={true}
                      color="success"
                      onClick={() => (quantity > 0 ? this.addToCart() : "")}
                      className="btn-block"
                    >
                      Legg {quantity} i handlevogn{" "}
                      <span style={{ fontSize: "12px" }}>
                        [ Kr. {editingProduct.price * quantity + extraPrice} ]
                      </span>
                    </Button>{" "}
                  </div>
                </ModalFooter>
              </Modal>
            ) : null}
            {/* End Disabled button on mobile view */}
            {cart.length > 0 ? (
              // <Col md="4" style={{ margin: "auto" }}>
              //   <Cart
              //     cart={cart}
              //     totalPrice={totalPrice}
              //     totalQuantity={totalQuantity}
              //     openDeliveryOptions={this.openDeliveryOptions}
              //     driveoutCharge={driveoutCharge}
              //     isTableOrder={false}
              //     taxes={this.props.taxes}
              //     removeItemFromCart={(id) => this.removeItemFromCart(id)}
              //     removeExtraFromCart={(proId, extraId, ix) =>
              //       this.removeExtraFromCart(proId, extraId, ix)
              //     }
              //     button="Continue"
              //   />
              // </Col>
              <ModalCart
                cart={cart}
                totalPrice={totalPrice}
                totalQuantity={totalQuantity}
                openDeliveryOptions={this.openDeliveryOptions}
                driveoutCharge={driveoutCharge}
                isTableOrder={false}
                taxes={this.props.taxes}
                showCartModal={this.state.showCartModal}
                showCartModalChange={this.showCartModal}
                handleModalClose={this.handleModalClose}
                // id={id}
                // proId={proId}
                // extraId={extraId}
                // ix={ix}
                removeItemFromCart={(id) => this.removeItemFromCart(id)}
                removeExtraFromCart={(proId, extraId, ix) =>
                  this.removeExtraFromCart(proId, extraId, ix)
                }
                button="Continue"
              />
            ) : // <Modal show={show} onHide={handleClose}>
              //   <Modal.Body>
              //     //{" "}
              //     <Cart
              //       cart={cart}
              //       totalPrice={totalPrice}
              //       totalQuantity={totalQuantity}
              //       openDeliveryOptions={openDeliveryOptions}
              //       driveoutCharge={driveoutCharge}
              //       isTableOrder={false}
              //       taxes={taxes}
              //       removeItemFromCart={(id) => removeItemFromCart(id)}
              //       removeExtraFromCart={(proId, extraId, ix) =>
              //         removeExtraFromCart(proId, extraId, ix)
              //       }
              //       button="Continue"
              //     />
              //   </Modal.Body>
              //   <Modal.Footer>
              //     <Button variant="secondary" onClick={handleClose}>
              //       Close
              //     </Button>
              //   </Modal.Footer>
              // </Modal>
              null}

            <Row className="mainWrapper">
              <div class="navbar position-fixed">
                {/* <div className="col-4">
                  <img src={logo}/>
                </div> */}

                <div
                  className="col-8 logo d-flex"
                  // style={{ textTransform: "uppercase" }}
                >
                  <div>
                    <img
                      style={{ width: "25px" }}
                      src={`${globalConst.s3url}${restLogo}`}
                      // style={{ width: 200 }}
                      alt="img"
                    />

                    {/* <img src={this.props.name} style={{width:"30px"}}/> */}
                  </div>
                  <div className="restaurantName">{this.props.name}</div>
                  <p className="pl-2">({this.props.address})</p>
                </div>
                <div className="col-4" style={{ textAlign: "right" }}>
                  <button
                    // color="success"
                    onClick={() => this.showCartModal()}
                    role="button"
                  // className="btn-block"
                  >
                    <FaShoppingCart className="cartWrapper" />
                  </button>
                  {/* <FaShoppingCart
                    onClick={() => (quantity > 0 ? this.addToCart() : "")}
                    className="cartWrapper"
                  /> */}
                  <span class="badge badge-warning">
                    {this.props.totalQuantity}
                  </span>
                </div>
              </div>

              {/* <div className="container-fluid position-fixed" style={{zIndex:"9999"}}>
            <div className="row justify-content-between">
    <div className="col-4" style={{textAlign:"center"}}>
     RESTAURANT'S NAME
    </div>
    <div className="col-4" style={{textAlign:"right"}}>
    <FaShoppingCart/>
    </div>
  </div>
  </div> */}

              <Col md="12" className="wrapper p-0">
                {/* <div style={{ marginTop: 16, paddingLeft: 27 }}> */}
                <div className="data">
                  <ul className="CategoryListing">
                    {this.props.categoriesData &&
                      !this.props.loading &&
                      this.state.currentPram &&
                      this.props.colors
                      ? this.props.categoriesData.map((itm, index) => {
                        return (
                          <li
                            key={index}
                            onClick={() => this.getProds(itm)}
                            // onMouseEnter={()=> }
                            style={
                              itm.name === this.state.currentCategoryName
                                ? {
                                  // background: this.props.colors.categoryBackColor.toString(),
                                  // border: `2px solid ${this.props.colors.categoryBackColor.toString()}`,
                                  cursor: "pointer",
                                  backgroundColor: "#d6a666",
                                  border: "2px solid none"
                                }
                                : {
                                  // border: `2px solid ${this.props.colors.categoryBackColor.toString()}`,
                                  cursor: "pointer",
                                  border: "2px solid none"
                                }
                            }
                          >
                            <a
                              style={
                                itm.name === this.state.currentCategoryName
                                  ? {
                                    // color: this.props.colors.categoryTitleColor.toString()
                                    color: "#07182c"
                                  }
                                  : {
                                    color: "#07182c"
                                  }
                              }
                            >
                              {itm.name}
                              {"\n"}
                            </a>
                          </li>
                        );
                      })
                      : null}
                  </ul>
                </div>
                <div className="product-list-element">
                  {this.props.getProductsLoading ? (
                    <div style={{ marginLeft: "0" }}>
                      <SpinnerRoundFilled
                        size={50}
                        thickness={100}
                        speed={100}
                        color="#36ad47"
                      />
                    </div>
                  ) : (
                    <div className="ContentCenter Subcategory">
                      {/* <h6>Amerikansk Medium Pizza</h6> */}
                      <h6>{this.state.currentCategoryName}</h6>
                      <ul className="SubCategoryList">
                        {!this.state.currentProductLoading &&
                          this.props.colors &&
                          prodsData.map((product, index) => {
                            return (
                              <a
                                style={{
                                  cursor: "pointer",
                                  color: this.props.colors.viewProductColor
                                }}
                                onClick={() =>
                                  this.currentProductTopShow(product)
                                }
                              >
                                {/* Se produkt */}
                                {/* Add to cart */}

                                <li key={index}>
                                  <a
                                    onClick={() =>
                                      this.currentProductTopShow(product)
                                    }
                                    className="imgAnchor"
                                  >
                                    {/* <img src="images/Taco-pizza.jpg" /> */}

                                    {product.thumbnail ? (
                                      <img
                                        src={`${globalConst.s3url}${product.thumbnail
                                          }`}
                                      />
                                    ) : (
                                      <img
                                        src={`${globalConst.s3url}${product.image
                                          }`}
                                      />

                                    )}
                                  </a>

                                  <h3
                                    style={{
                                      // color: this.props.colors.productColor.toString()
                                      color: "black",
                                      width: "80%",
                                    }}
                                  >

                                    {/* <WindowSize /> */}
                                    {isMobile ? (
                                      <Button
                                        onClick={() => {

                                          this.toggle(
                                            product,
                                            // this.state.currentProduct,
                                            this.state.currentsuperCategory,
                                            this.state.currentcatType
                                          );
                                        }}
                                      >
                                        <div className="productPriceWrapper">
                                          <div>
                                            {product.name}
                                            {/* {console.log("product name--->",product.name)}
                                        {console.log("product price---->",product.price)} */}
                                          </div>
                                          <div className="productPrice">
                                            <h3 style={{ fontWeight: "bold" }}>(Kr.{" "}
                                              {product.price})</h3>
                                          </div>
                                        </div>


                                      </Button>
                                    ) : (
                                      <>{product.name}</>
                                    )}
                                  </h3>

                                  {/* <a
                                  style={{
                                    cursor: "pointer",
                                    color: this.props.colors.viewProductColor
                                  }}
                                  onClick={() =>
                                    this.currentProductTopShow(product)
                                  }
                                >
                                  {/* Se produkt */}
                                  {/* Add to cart */}
                                  {/* </a>  */}
                                </li>
                              </a>
                            );
                          })}
                      </ul>
                    </div>
                    // </Row>
                  )}
                  <br className="break" />
                </div>
                {this.state.prodsData.length > 0 && (
                  <div
                    className="ContentCenter img-cc"
                    style={{ background: "#dedede" }}
                  >
                    {/* <div className="CartSection">
                      <h3>Medium</h3>
                      <h3
                        style={{
                          color: this.props.colors
                            ? this.props.colors.productColor
                            : "white"
                        }}
                      >
                        {this.state.currentCategoryName}
                      </h3>
                      {this.props.colors ? (
                        <a
                          style={
                            (this.props.availability &&
                              !this.props.availability.status) ||
                            this.state.restaurantClosed === true
                              ? {
                                  display: "none"
                                }
                              : {
                                  color: this.props.colors.viewCartTextColor.toString(),
                                  border: `2px solid ${this.props.colors.orderNowBtnBackground.toString()}`,
                                  background: this.props.colors.orderNowBtnBackground.toString(),
                                  cursor: "pointer"
                                }
                          }
                          onClick={() => window.scrollTo(0, 0)}
                          role="button"
                        >
                          VIS HANDLEVOGN{" "}
                          <Badge
                            style={{ margin: 10, fontSize: "100%" }}
                            color="danger"
                          >
                            {cart.length}
                          </Badge>
                        </a>
                      ) : null}
                    </div> */}
                    <div className="ImgSec">
                      <div className="box">
                        <img
                          src={`${globalConst.s3url}${this.state.currentProduct.thumbnail
                            }`}
                          // style={{ width: 200 }}
                          alt="img"
                        />

                        <div className="OrderSection">
                          <div style={{ width: "70%" }}>
                            <h3
                              style={{
                                // color:
                                //   this.props.colors && this.props.colors.productColor
                                color: "#1B2F44",
                                fontWeight: "bold"
                              }}
                            >
                              {this.state.currentProduct.name}&nbsp; (Kr.{" "}
                              {this.state.currentProduct.price})
                            </h3>
                            <p
                              style={{
                                color:
                                  this.props.colors &&
                                  this.props.colors.ingredentTextColor,

                                fontStyle: "italic"
                              }}
                            >
                              <b style={{ color: "#898989" }}>
                                {this.state.currentProduct.ingredents &&
                                  this.state.currentProduct.ingredents.length > 0
                                  ? "Ingredienser: "
                                  : ""}
                              </b>

                              {this.state.currentProduct.ingredents &&
                                this.state.currentProduct.ingredents.length > 0
                                ? this.getIngedentDeatails(
                                  this.state.currentProduct.ingredents
                                )
                                : ""}
                            </p>
                    
                          <span
                              style={{
                                color:
                                  this.props.colors &&
                                  this.props.colors.ingredentTextColor,
                                  fontStyle: "bold"
                              }}
                            >
                                       Beskrivelse: 
                                      </span> {(this.state.currentProduct.ingredentsDesc).split("\n").map((e)=>{
                                    return(<span>{e}<br></br></span>);        
                            })}
                            
                            <h4
                              style={{
                                // color:
                                //   this.props.colors && this.props.colors.priceColor
                                color: "#1B2F44"
                              }}
                            >
                              {/* Kr. {this.state.currentProduct.price} */}
                            </h4>
                          </div>
                          {this.props.colors ? (
                            <div
                              style={{ width: "30%" }}
                              className="orderButton"
                            >
                              <a
                                style={
                                  (this.props.availability &&
                                    !this.props.availability.status) ||
                                    this.state.restaurantClosed === true ||this.props.isTreeDriveAllowed
                                    ? {
                                      display: "none",
                                      color: "white",
                                      cursor: "pointer"
                                    }
                                    : {
                                      // color: this.props.colors.orderNowTextColor.toString(),
                                      // background: this.props.colors.orderNowBtnBackground.toString(),
                                      backgroundColor: "#d6a666",
                                      cursor: "pointer",
                                      color: "white"
                                    }
                                }
                                onClick={() =>
                                  this.toggle(
                                    this.state.currentProduct,
                                    this.state.currentsuperCategory,
                                    this.state.currentcatType
                                  )
                                }
                              >
                                BESTILL NÅ
                                {/* Order Now */}
                              </a>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                )}{" "}
                {this.props.getProductsLoading == false &&
                  this.state.prodsData.length == 0 && (
                    <h1 style={{ textAlign: "center", marginTop: "5rem" }}>
                      Sorry this item is not available right now
                    </h1>

                  )}
                {/* <hr style={{ width: "50%" }} /> */}
              </Col>
            </Row>
          </div>
          {/* End of tabs<Modal isOpen={modal} > */}

          {this.props.availability ? (
            <Modal
              isOpen={
                this.props.availability.status && !this.state.restaurantClosed
                  ? modal
                  : false
              }
              toggle={this.onlytoggleModal}
            >
              <ModalHeader toggle={this.onlytoggleModal}>
                {editingProduct.name}

                <br />
                <span style={{ color: "grey", fontSize: "15px" }}>
                  Pris : Kr. {editingProduct.price}
                </span>

              </ModalHeader>
              <ModalBody>

                <div className="row">
                  <div className="col-xs-2 col-md-4">
                    <img
                      src={`${globalConst.s3url}${editingProduct.image}`}
                      alt={editingProduct.name}
                      style={{
                        maxWidth: "100%",
                        height: "100px",
                        alignSelf: "center",
                        verticalAlign: "center",
                        marginBottom: "15px"
                        // marginLeft: "20%"
                      }}
                    />
                    {isMobile && (
                      <>

                        <ul>
                          <li>
                            {this.state.currentProduct.ingredentsDesc && this.state.currentProduct.ingredentsDesc}
                          </li>
                        </ul>
                      </>
                    )}

                  </div>
                  <div className="col-xs-6 col-md-4">
                    <div className="row">
                      <div className="col-lg-12">
                        <h6>Ingredienser</h6>
                        <ul className="checkboxList">
                          {editingProduct &&
                            editingProduct.ingredents &&
                            editingProduct.ingredents.map((ing, index) => {
                              return (
                                <li key={index} className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    onChange={(e) =>
                                      this.handleInstructionText(
                                        e,
                                        index,
                                        ing._id,
                                        ing,
                                        stockNameIngredents
                                      )
                                    }
                                    value={ing.stockproduct.name}
                                    checked={
                                      this.state.incrementChecked[index]
                                        ? this.state.incrementChecked[index]
                                        : false
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexCheckDefault"
                                  >
                                    {ing.stockproduct.name}
                                  </label>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-6 col-md-4">
                    <div className="row">
                      <div className="col-lg-12">
                        {this.state.currentProduct &&
                          this.state.currentProduct.isExtraInformation && (
                            <>
                              <h6>Spiciness</h6>
                              <ul className="checkboxList cardHover">
                                <li className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    onChange={() =>
                                      this.clickedExtraInstructions("Mild")
                                    }
                                    value="Mild"
                                    checked={
                                      this.state.checkedInstruction === "Mild"
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexCheckDefault"
                                  >
                                    {"Mild"}
                                  </label>
                                </li>
                                <li className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    onChange={(e) =>
                                      this.clickedExtraInstructions("Spicy")
                                    }
                                    value="Spicy"
                                    checked={
                                      this.state.checkedInstruction === "Spicy"
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexCheckDefault"
                                  >
                                    {"Spicy"}
                                  </label>
                                </li>
                                <li className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    onChange={() =>
                                      this.clickedExtraInstructions(
                                        "ExtraSpicy"
                                      )
                                    }
                                    value="ExtraSpicy"
                                    checked={
                                      this.state.checkedInstruction ===
                                      "ExtraSpicy"
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexCheckDefault"
                                  >
                                    {"ExtraSpicy"}
                                  </label>

                                </li>

                              </ul>


                            </>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <h6>Legg til kommentar (valgfritt):</h6>
                    <textarea
                      name="instruction"
                      onChange={(e) => this.handleInstruction(e)}
                      value={instruction}
                      style={{
                        width: "80%",
                        borderWidth: 1
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    {this.props.extra
                      ? this.props.extra.map((extra, ix) => {
                        return (
                          <div className="row" key={ix}>
                            <div className="col-lg-12">
                              <div className="text-left">
                                <a
                                  onClick={() => this.handleExtraOpen(ix)}
                                  aria-expanded={
                                    this.state.extraItemsOpen[ix]
                                  }
                                  aria-controls="example-collapse-text"
                                  className="sm-btn"
                                  href="#"
                                >
                                  {`+ ${extra.name}`}
                                </a>
                              </div>
                              {this.state.extraItemsOpen &&
                                this.state.extraItemsOpen[ix] ? (
                                <ul className="list-group extraList">
                                  {extra &&
                                    extra.items.map((item, idx) => {
                                      return (
                                        <li
                                          className="list-group-item"
                                          key={idx}
                                        >
                                          <div className="form-check">
                                            <label className="form-check-label">
                                              <input
                                                onChange={(e) =>
                                                  this.handleAddExtraItems(
                                                    e,
                                                    ix,
                                                    idx,
                                                    item
                                                  )
                                                }
                                                type="checkbox"
                                                className="form-check-input"
                                                value={item._id}
                                                checked={
                                                  this.state.checkedExtra[ix][
                                                  idx
                                                  ]
                                                }
                                              />{" "}
                                              <img
                                                style={{
                                                  width: "35px",
                                                  height: "35px"
                                                }}
                                                src={`${globalConst.s3url}${item.image
                                                  }`}
                                                width="10%"
                                              />{" "}
                                              {`${item.name} (kr. ${item.price
                                                })`}
                                            </label>
                                          </div>
                                        </li>
                                      );
                                    })}
                                </ul>
                              ) : null}
                            </div>
                          </div>
                        );
                      })
                      : ""}
                  </div>
                </div>
              </ModalBody>
              <ModalFooter style={{ flexWrap: "nowrap" }}>
                <div className="col-md-4 col-xs-2">
                  <div className="add_product">
                    <Button
                      onClick={this.decreaseItem}
                      outline
                      color="secondary"
                      className="minus_btn"
                    >
                      -
                    </Button>
                    <input
                      className="form-control"
                      type="text"
                      value={quantity}
                      onChange={(e) => this.handleChange(e)}
                    />
                    <Button
                      onClick={this.incrementItem}
                      outline
                      color="secondary"
                      className="add_btn"
                    >
                      +
                    </Button>
                  </div>
                </div>

                <div className="col-md-8 col-xs-6">
                  <Button
                    color="success"
                    onClick={() => (quantity > 0 ? this.addToCart() : "")}
                    className="btn-block"
                  >
                    Legg {quantity} i handlevogn{" "}
                    <span style={{ fontSize: "12px" }}>
                      [ Kr. {editingProduct.price * quantity + extraPrice} ]
                    </span>
                  </Button>{" "}
                </div>
              </ModalFooter>
            </Modal>
          ) : null}

          <Modal
            isOpen={popup}
            toggle={this.openDeliveryOptions}
            className={this.props.className}
          >
            <ModalHeader toggle={this.openDeliveryOptions}>
              Hvilken leveranse metode ønsker du?
            </ModalHeader>

            <ModalBody className="delivery-options-buttons">
              {this.props.additional && this.props.additional.delivery ? (
                <Button
                  color="success"
                  onClick={() => {
                    history.push("/checkout/deliver");
                  }}
                >
                  Levering
                  {/* Deliver Food */}
                </Button>
              ) : null}
              {this.props.additional && this.props.additional.pickup ? (
                <Button
                  color="success"
                  onClick={() => history.push("/checkout/pickup")}
                >
                  Hente selv
                </Button>
              ) : null}
            </ModalBody>
          </Modal>
        </div>
      );
    }
  }
}

const mapStateToProps = ({ products, restaurant, order }) => {
  const driveoutCharge = restaurant.driveoutCharge;

  const {
    availability,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday,
    additional,
    categoryBackColor,
    colors,
    taxes,
    name,
    address,
    isTreeDriveAllowed
  } = restaurant;

  const {
    activeTab,
    items,
    loading,
    error,
    cart,
    totalQuantity,
    totalPrice,
    modal,
    editingProduct,
    quantity,
    popup,
    isOpen,
    instruction,
    getCategoriesLoading,
    getCategoriesSuccess,
    getCategoriesError,
    categoriesData,
    getProductsLoading,
    getProductsSuccess,
    getProductsError,
    productsData,
    extra,
    getExtraItemLoading,
    getExtraItemSuccess,
    getExtraItemError,
    addExtraPrice,
    extraPrice,
    extraItems
  } = products;
  const {
    supercategoryLoading,
    supercategorySuccess,
    supercategoryData
  } = order;
  return {
    activeTab,
    items,
    loading,
    error,
    cart,
    totalQuantity,
    totalPrice,
    modal,
    editingProduct,
    quantity,
    popup,
    isOpen,
    driveoutCharge,
    instruction,
    getCategoriesLoading,
    getCategoriesSuccess,
    getCategoriesError,
    categoriesData,
    getProductsLoading,
    getProductsSuccess,
    getProductsError,
    productsData,
    availability,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday,
    additional,
    categoryBackColor,
    colors,
    supercategoryLoading,
    supercategorySuccess,
    supercategoryData,
    extra,
    extraPrice,
    extraItems,
    taxes,
    name,
    address,
    isTreeDriveAllowed
    
  };
};

export default connect(mapStateToProps)(Menu);
