import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import image from "../../src/assets//95381-qrcode.gif";
import globalConst from "../helpers/global";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";

import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
  Col,
  Alert,
  Collapse,
  ListGroup,
  ListGroupItem,
  Card,
  CardImg,
  CardHeader,
  Container,
  CardBody,
  CardTitle,
  Form,
  Spinner,
  FormGroup,
  Label,
  FormControl,
  CardText,
  Input,
  Badge
} from "reactstrap";
import {
  saveTable,
  deleteTable,
  getRestaurantInfo,
  clearStorage
} from "../actions";
import { deleteCart } from "../actions/cartActions";
import { socket } from "../helpers/globSocket";
import openSocket from "socket.io-client";
const TableNumber = (props) => {

  const [completeUrl, setCompleteUrl] = useState("");
  const [customerId, setCustomerId] = useState("");

  const slug = props.match.params.slug;

  const [tableNo, setTableNo] = useState("");

  const dispatch = useDispatch();
  const isLoading = useSelector(
    (rdxstate) => rdxstate.order.saveTableCheckLoading
  );
  const { isQrAllow, name, logo ,isQrAllowByRest} = useSelector(
    (rdxstate) => rdxstate.restaurant
  );

  const isError = useSelector((rdxstate) => {
    return rdxstate.order.tableError;
  });

  useEffect(() => {

    let appCustomerId = '';

    appCustomerId = props.match.params.appCustomerId;
    console.log('Customer Id---', appCustomerId);

    ///This is to get by ?query url
    let url = new URL(window.location.href);
    // console.log(url.href);
    // let searchUrl = new URLSearchParams(url.search);
  
    // console.log(searchUrl.has('appCustomerId'), searchUrl.get('appCustomerId'));
  
    // if(searchUrl.has('appCustomerId')){
    //   appCustomerId = searchUrl.get('appCustomerId');
    //   console.log(appCustomerId);
    // }

    setCompleteUrl(url.href);
    setCustomerId(appCustomerId);

    localStorage.clear();
    dispatch(deleteCart());
    dispatch(clearStorage());
    dispatch(getRestaurantInfo(slug));
    socket.on("restAvailability", () => {
     
      localStorage.clear();
      dispatch(deleteCart());
      dispatch(clearStorage());
      dispatch(getRestaurantInfo(slug));
    });

    // dispatch(deleteTable(slug));
  }, []);

  const isTableSuccess = useSelector(
    (rdxstate) => rdxstate.order.saveTableCheckSuccess
  );
  const isRestLoading = useSelector((rdxstate) => rdxstate.restaurant.loading);
  useEffect(() => {
    if (isTableSuccess) {
      props.history.push(`/restaurant/tableorder/` + slug);
    }
  }, [slug, isTableSuccess]);

  useEffect(() => {}, [isRestLoading]);

  useEffect(() => {});
  const onCreateTable = (event) => {
    event.preventDefault();
    if (tableNo == 0) {
      return;
    }
    dispatch(
      saveTable({
        slug: slug,
        number: tableNo,
        appCustomerId: customerId
      })
    );
  };

  return (
    <Container className="tableContainer" fluid>
      {isRestLoading && <Spinner color="light" />}
      {(!isQrAllow ||!isQrAllowByRest )&& !isRestLoading && (
        <h3
          style={{ color: "white" }}
        >{`Sorry this service not available `}</h3>
      )}
      {isQrAllow && isQrAllowByRest && !isRestLoading && (
        <Card className="col-md-6">
          <div style={{ textAlign: "center", paddingTop: "5px" }}>
            <CardImg
              src={`${globalConst.s3url}${logo}`}
              style={{ width: 80, float: "center" }}
            />
          </div>
          {name && (
            <div>
              <h4
                style={{
                  textAlign: "center",
                  fontWeight: "800",
                  textTransform: "capitalize"
                }}
              >
                {name}
              </h4>
              <p
                style={{
                  fontSize: "12px",
                  textAlign: "center",
                  fontStyle: "italic"
                }}
              >
                Enjoy full menu on your phone.
              </p>



              
            </div>
          )}

          <div className="d-flex justify-content-center">
            {" "}
            <img variant="top" src={image} style={{ width: 150 }} />
          </div>

          <CardBody>
            <CardTitle
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "16px"
              }}
            >
              Table number{" "}
            </CardTitle>
            {false && (
              <div style={{ textAlign: "center" }}>
                <Spinner color="primary" />
              </div>
            )}

            {true && (
              <Form onSubmit={onCreateTable}>
                <FormGroup>
                  <Input
                    type="number"
                    value={tableNo}
                    min="1"
                    placeholder=" Enter your table number"
                    max="10000"
                    onChange={(event) => {
                      setTableNo(event.target.value);
                    }}
                  />
                </FormGroup>

                <div style={{ textAlign: "center" }}>
                  <Button
                    color="primary"
                    disabled={isLoading}
                    className="bookBtn "
                  >
                    {isLoading ? (
                      <Spinner size="sm" color="light" />
                    ) : (
                      "View Menu"
                      // "Book Table"
                    )}
                  </Button>
                  {isError && (
                    <p style={{ color: "red", marginTop: 20 }}>
                      This table's already occupied.
                    </p>
                  )}
                </div>
              </Form>
            )}
          </CardBody>
        </Card>
      )}
    </Container>
  );
};
export default TableNumber;
