import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Card, CardHeader, CardBody, Button } from "reactstrap";
import { connect } from "react-redux";
import queryString from "query-string";
import {
  clearStorage,
  order_transaction_check,
  table_order_transaction_check
} from "../actions";
import remove from "../assets/remove.png";
// import { Component } from "spinners-react";

class TableOrderConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transaction_id: "",
      loading: false,
      paymentLoading: true,
      paymentType: 'Tidy',
      queryString: ''
    };
  }
  componentDidMount() {
    var rr = this.useQuery();

    this.setState({
      orderId: rr,
      queryString: queryString.parse(this.props.location.search)
    });

    setTimeout(() => {
      if (rr) {
        this.setState({
          paymentLoading: false
        });

        this.orderCheck(rr);
        // this.props.dispatch(clearStorage());
      }
    }, 2000);
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.ordertransactionCheckSuccess ==
      this.props.ordertransactionCheckSuccess
    ) {
    }

    console.log(
      "hellooo",
      this.props.ordertransactionCheckData,
      this.props.ordertransactionCheckData.message
    );

    if (
      this.props.ordertransactionCheckData &&
      this.props.ordertransactionCheckData.message == "UNAUTHORIZED"
    ) {
      this.props.dispatch(clearStorage());
    } else {
    }

    
  }
  useQuery() {
    var quey = queryString.parse(this.props.location.search);
    // this.setState({ transaction_id: quey.transaction_id });
    //return quey.merchant_reference;

    let id = '';

    if(quey.merchant_reference){
      id = quey.merchant_reference;
      this.setState({
        paymentType: 'Dintero'
      })
    }

    if(quey.responseCode){
      id = quey.transactionId
    }

    return id;
  }
  
  orderCheck(id) {

    console.log('TidyPay response is: ', this.state.queryString);

    var postBody = {
      id: id,
      paymentType: this.state.paymentType,
      tidyPayResponse: this.state.queryString,
      paymentResponse: this.state.queryString
    };

    let { dispatch } = this.props;
    dispatch(table_order_transaction_check(postBody));

  }

  // const OTP = ({ otp, dispatch, history, slug }) => {

  clearState = () => {
    this.props.dispatch(clearStorage());
    // dispatch(deleteTable(slug))
    this.props.history.push(`/restaurant/booktable/${this.props.slug}`);
    // this.props.history.push(`/restaurant/booktable/${this.props.slug}`);
  };
  render() {
    return (
      <Card className="checkout-card">
        <CardHeader>Order Details</CardHeader>
        {this.props.ordertransactionCheckLoading ||
        this.state.paymentLoading ? (
          <h3 align="center">Loading..</h3>
        ) : (
          <CardBody className="otp-card">
            {(this.props.ordertransactionCheckData &&
              this.props.ordertransactionCheckData.message == "UNAUTHORIZED") ||
            this.props.ordertransactionCheckError ? (
              <img src={remove} height={140} />
            ) : (
              <div className="checkmark-circle">
                <div className="background" />
                <div className="checkmark draw" />
              </div>
            )}

            {(this.props.ordertransactionCheckData &&
              this.props.ordertransactionCheckData.message == "UNAUTHORIZED") ||
            this.props.ordertransactionCheckError ? (
              <div>
                <p>
                  Something went wrong
                  <br />
                  Your order is not placed
                </p>
                <Button
                  color="success"
                  outline
                  onClick={() => this.clearState()}
                >
                  Gå til nettsiden
                </Button>
                <Button
                  color="success"
                  outline
                  onClick={() => {
                    this.props.history.push("/tableorder/cart");
                  }}
                >
                  Prøv å betale på nytt
                </Button>
              </div>
            ) : (
              <div>
                <h4>
                  <strong>Order Confirmed</strong>
                </h4>
                <p>
                  {/* You need to provide OTP at the restaurant to collect your order. */}
                  Your food will reach on your table soon
                </p>
                <Button
                  color="success"
                  outline
                  onClick={() => this.clearState()}
                >
                  {/* Place New Order */}
                  Legg inn ny ordre
                </Button>
              </div>
            )}
          </CardBody>
        )}
      </Card>
    );
  }
}

const mapStateToProps = ({ order, restaurant }) => {
  return {
    otp: order.otp,
    id: restaurant.id,
    slug: restaurant.slug,
    orderType: order.orderTypes,
    ordertransactionCheckLoading: order.ordertransactionCheckLoading,
    ordertransactionCheckSuccess: order.ordertransactionCheckSuccess,
    ordertransactionCheckError: order.ordertransactionCheckError,
    ordertransactionCheckData: order.ordertransactionCheckData
    // orderPaymentCheckLoading: order.orderPaymentCheckLoading,
    // orderPaymentCheckSuccess: order.orderPaymentCheckSuccess,
    // orderPaymentCheckData: order.orderPaymentCheckData
  };
};

export default withRouter(connect(mapStateToProps)(TableOrderConfirm));