import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import globalConst from "../helpers/global";
import { Button, Modal, Checkbox, Collapse, Descriptions } from "antd";
import { getTaxModule } from "../helpers/taxmodule";
import offer from "../assets/offer.png";
import coinImg from "../assets/SMALLCOIN.png";
import axios from "axios";

export default function GetAllData(props) {
  let data = props.data;
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedImg, setSelectedImg] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [cartProducts, setCartProducts] = useState([]);
  const [totalOfProducts, setTotalOfProducts] = useState();
  const [selectedItemIndex, setSelectedItemIndex] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isPaymentModalOpened, setPaymentIsModalOpened] = useState(false);
  const [totalWithExtras, setTotalWithExtras] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [itemToDelete, setItemToDelete] = useState();
  const [offerEnable, setOfferEnable] = useState();
  const [offerPrice, setOfferPrice] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [loading, setLoading] = useState(false);
  const [tax, setTax] = useState(0);
  const [taxValue, setTaxValue] = useState(0);
  const [previousId, setPreviousId] = useState([]);
  // const [paymentDintero, setPaymentDintero] = useState();

  const restaurant = useSelector((state) => {
    return state.restaurant;
  });
  useEffect(() => {
    // Set the first item as the default selected item when the component mounts
    if (props.alldata.length > 0) {
      setSelectedItemIndex(0);
      setSelectedCategory(props.alldata[0]);
    }
  }, [props.alldata]);

  useEffect(() => {}, [isModalOpened]);

  useEffect(() => {
    setTaxValue((100 + Number(tax)) / 100);
  }, [tax]);

  useEffect(() => {
    TotalAmountOfProducts();
  }, [cartProducts]);

  useEffect(() => {
    setSelectedItems([]);
  }, [cartProducts]);

  useEffect(() => {
    // const tableNumber = JSON.parse(localStorage.getItem("tableData"));
    const myTax = getTaxModule(restaurant.taxes, "table");
    setTax(myTax);
  }, []);
  const RemoveChecks = () => {
    const allWithClass = Array.from(document.getElementsByClassName("mycheck"));
    allWithClass.map((ele) => {
      ele.checked = false;
    });
  };
  const showModal = (prod) => {
    setItemToDelete(prod);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    deleteSingleItem(itemToDelete);
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showModaled = (prod) => {
    RemoveChecks();
    setSelectedProduct(prod);
    setIsModalOpened(true);
  };
  const filterItemsCategory = (img, index) => {
    setSelectedImg(img);
    // setSelectedItemIndex(index);
  };

  const handledOk = () => {
    setIsModalOpened(false);
  };

  const handleCanceled = (selected) => {
    let itemsDeleted = selectedItems.filter(
      (item) => item._id !== selected._id
    );
    const newTotal = itemsDeleted.reduce(
      (total, item) => total + Number(item.price),
      0
    );
    setTotalWithExtras(newTotal);
    RemoveChecks();
    setIsModalOpened(false);
  };

  const filterItems = (product, index) => {
    setSelectedCategory(product);
    setSelectedItemIndex(index);
  };

  const clearCart = () => {
    setCartProducts([]);
    setOfferPrice([]);
  };

  const TotalAmountOfProducts = () => {
    const productTotal = cartProducts.reduce((oldValue, newValue) => {
      let price = newValue.price;
      let todayDate = new Date();
      if (productOfferEnabled(newValue)) {
        price = newValue.offer.offerPrice;
      }
      // const price = newValue.offerEnable
      //   ? newValue.offer.offerPrice
      //   : newValue.price;

      return (oldValue += newValue.quantity * price);
    }, 0);
    console.log("total of products", productTotal);
    setTotalOfProducts(productTotal);
  };

  const deleteSingleItem = (item) => {
    let itemDeleted = cartProducts.filter((e) => e._id !== item._id);
    setCartProducts(itemDeleted);
  };

  const addExtraItems = (subExtras, e) => {
    // let extrasId = subExtras._id;
    // let extraItems = [];

    if (e.target.checked) {
      let item = {};
      item._id = subExtras._id;
      item.name = subExtras.name;
      item.pricee = subExtras.price;
      // extraItems.push(item);
      let pp = selectedProduct;
      // pp.extraItems = item;

      const updatedSelectedItems = [...selectedItems, subExtras];

      const newTotal = updatedSelectedItems.reduce(
        (total, item) => total + Number(item.price),
        0
      );

      setTotalWithExtras(newTotal);
      setSelectedItems(updatedSelectedItems);
    } else {
      const updatedSelectedItems = selectedItems.filter((item) => {
        return item._id !== subExtras._id;
      });

      const newTotal = updatedSelectedItems.reduce(
        (total, item) => total + Number(item.price),
        0
      );
      setTotalWithExtras(newTotal);
      setSelectedItems(updatedSelectedItems);
    }
  };

  const productNames = cartProducts.map((product) => ({
    id: product._id,
    name: product.name,
    quantity: product.quantity
    // price: product.offerEnable ? product.offer.offerPrice : product.price
  }));

  const requestData = {
    ...data,
    cartProducts: productNames,
    itemsNames: itemsNames,
    isWebUser: false,
    orderFrom: "WEB"
  };
  // const paymenthandleOk = () => {
  //   setPaymentIsModalOpened(false);
  // };

  // const paymenthandleCancel = () => {
  //   setPaymentIsModalOpened(false);
  // };

  const [response, setResponse] = useState(null);

  const proceedPayment = async () => {
    try {
      const response = await axios.post(
        "https://api.a-board.tech/api/v1/public/reservetable",
        requestData,
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );

      if (response.status === 200) {
        const paymentUrl = response.data.data.url; // Extract the URL from the response
        setResponse(requestData); // Store the requestData or any necessary response data
        // Redirect the user to the payment URL
        window.location.href = paymentUrl;
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  // Fix for itemsNames
  const itemsNames = cartProducts.map((product) => product.name).join(", ");

  const addToCart = (prod, productAction) => {
    if (!prod) {
      console.error("Product is null or undefined");
      return;
    }

    let d = cartProducts.filter((e) => e._id == prod._id);
    if (d.length > 0) {
      let updatedItem = cartProducts.map((obj) => {
        if (obj._id == prod._id) {
          let nQuantity = obj.quantity;
          let prodPrice = prod.price;

          if (productAction == "add") {
            nQuantity += 1;
          }

          if (productAction == "minus") {
            if (obj.quantity < 2) {
              showModal(prod);
            } else {
              nQuantity -= 1;
            }
          }

          if (productOfferEnabled(prod)) {
            prodPrice = prod.offer.offerPrice;
          }

          let ntotalProductPrice = nQuantity * prodPrice;

          return {
            ...obj,
            quantity: nQuantity,
            totalProductPrice: ntotalProductPrice
          };
        }

        return obj;
      });
      setCartProducts(updatedItem);
    } else {
      let this_prod = prod;
      let todayDate = new Date();

      let prodPrice = prod.price;

      if (productOfferEnabled(prod)) {
        prodPrice = prod.offer.offerPrice;
      }

      this_prod.quantity = 1;
      this_prod.totalProductPrice = this_prod.quantity * prodPrice;

      setCartProducts((old) => [...old, this_prod]);
      setOfferEnable(prod.offerEnable);
      setOfferPrice(prod.offer.offerPrice);
      setStartDate(prod.offer.startDate);
      setEndDate(prod.offer.endDate);
    }
  };

  const productOfferEnabled = (prod) => {
    let todayDate = new Date();
    if (prod.offerEnable) {
      let offerStartDate = new Date(prod.offer.startDate);
      let offerEndDate = new Date(prod.offer.endDate);

      if (todayDate >= offerStartDate && todayDate <= offerEndDate) {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      {loading ? (
        <div className="d-flex align-items-center justify-content-center loading ">
          <Spinner
            animation="border"
            variant="light"
            className="d-flex justify-content-center align-items-center"
            style={{
              height: "120px",
              width: "120px",
              borderStyle: "solid",
              borderWidth: "8px"
            }}
          />
        </div>
      ) : (
        <>
          <Row className="bg-image p-5 d-flex justify-content-between">
            <Col span={6} className="mainCategory bg-white rounded">
              <div className="box">
                {props.alldata.map((item, index) => (
                  <a
                    key={item._id} // Ensure _id is unique and stable
                    onClick={() => filterItems(item, index)}
                    className={`d-flex productItem ${
                      selectedItemIndex === index ? "productitem" : ""
                    }`}
                  >
                    <h4>{item.name}</h4>{" "}
                  </a>
                ))}
              </div>
            </Col>
            <Col
              span={8}
              className="bg-white h-100 middle-box rounded"
              style={{ overflowY: "scroll" }}
            >
              <div className="box">
                {selectedCategory &&
                  selectedCategory.products.map((product, index) => (
                    <Row
                      key={index}
                      className="d-flex justify-content-between Category-Image"
                    >
                      {productOfferEnabled(product) ? (
                        <Col span={20} className="d-flex">
                          <img
                            src={`${globalConst.s3url}${selectedCategory.image}`}
                            alt="Category Image"
                            width="20%"
                            className="p-2"
                          />
                          <div className="d-flex flex-column justify-content-center">
                            <h4>{product.name}</h4>
                            <div className="d-flex">
                              <h5
                                style={{ fontWeight: "bold" }}
                                className="mb-0"
                              >
                                Kr.{product.offer.offerPrice}
                              </h5>
                              &nbsp;&nbsp;
                              <h5
                                style={{ textDecorationLine: "line-through" }}
                                className="mb-0"
                              >
                                Kr.{product.price}
                              </h5>
                              {productOfferEnabled(product) && (
                                <img
                                  src={offer}
                                  alt="offer available offerimg"
                                  width={70}
                                />
                              )}
                            </div>
                          </div>
                        </Col>
                      ) : (
                        <Col span={20} className="d-flex align-items-center">
                          <img
                            src={`${globalConst.s3url}${selectedCategory.image}`}
                            alt="Category Image"
                            width="20%"
                            className="p-2"
                          />
                          <div className="d-flex flex-column">
                            <h4>{product.name}</h4>
                            <h5
                              style={{
                                fontWeight: "bold"
                              }}
                            >
                              Kr.{product.price}
                            </h5>
                          </div>
                        </Col>
                      )}
                      <Col
                        span={4}
                        className="d-flex"
                        onClick={() => filterItemsCategory(product, index)}
                      >
                        <button
                          className="addToCartbtn w-100 h-100"
                          onClick={() => addToCart(product, "add")}
                        >
                          Add
                        </button>
                      </Col>
                    </Row>
                  ))}
              </div>
            </Col>
            <Col span={9} className="bg-white rounded-lg">
              <Row className="h-100 w-100 justify-content-between">
                <Col
                  span={24}
                  className="w-100 p-4"
                  style={{ height: "60vh", overflowY: "scroll" }}
                >
                  {selectedImg && (
                    <div className="menu-images d-flex flex-column align-items-start justify-content-center">
                      {cartProducts.map((product) => (
                        <Row className="cartBox reserveCart mb-3">
                          <Col key={product._id} span={16} className="pr-3">
                            <div>
                              <h5>{product.name}&nbsp;</h5>
                            </div>
                            <a
                              onClick={() => showModal(product)}
                              className="closeicon text-bold"
                            >
                              <u style={{ color: "red" }}>Delete</u>
                            </a>
                          </Col>
                          <Col
                            span={4}
                            className="d-flex justify-content-between"
                          >
                            {product.quantity > 1 ? (
                              <button
                                className="minus-btn dec"
                                onClick={() => addToCart(product, "minus")}
                              >
                                -
                              </button>
                            ) : (
                              <>&nbsp;&nbsp;</>
                            )}
                            <p className="mb-0">{product.quantity}</p>
                            <button
                              className="inc"
                              onClick={() => addToCart(product, "add")}
                            >
                              +
                            </button>
                          </Col>
                          <Col
                            span={4}
                            className="d-flex justify-content-end align-items-center"
                          >
                            <h4>Kr.{product.totalProductPrice}</h4>
                          </Col>
                        </Row>
                      ))}
                    </div>
                  )}
                </Col>
                <Col span={24} className="text-right">
                  <Descriptions title="Payment Break Out" bordered>
                    <Descriptions.Item label="Sub-Total:">
                      Kr.
                      {offerEnable
                        ? totalOfProducts &&
                          (totalOfProducts + totalWithExtras) / taxValue
                        : totalOfProducts &&
                          (totalOfProducts + totalWithExtras) / taxValue}
                    </Descriptions.Item>
                  </Descriptions>
                  <Descriptions bordered>
                    <Descriptions.Item label={`Tax (${tax}%):`}>
                      Kr.{" "}
                      {(offerEnable
                        ? totalOfProducts -
                          totalOfProducts / taxValue +
                          totalWithExtras -
                          totalWithExtras / taxValue
                        : totalOfProducts -
                          totalOfProducts / taxValue +
                          totalWithExtras -
                          totalWithExtras / taxValue
                      ).toFixed(2)}
                    </Descriptions.Item>
                  </Descriptions>
                  <Descriptions bordered>
                    <Descriptions.Item label="Total Amount">
                      Kr.
                      {totalWithExtras + totalOfProducts}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                <Col span={24} className="d-flex rounded-bottom">
                  <button
                    onClick={(e) => clearCart(e)}
                    className="clearcart-btn w-100 rounded-bottom"
                    style={{
                      backgroundColor: "red",
                      border: "0",
                      color: "white"
                    }}
                  >
                    Clear Cart
                  </button>
                  <button
                    onClick={() => proceedPayment()}
                    className="clearcart-btn w-100 rounded-bottom"
                    style={{
                      backgroundColor: "#13cd52",
                      border: "0",
                      color: "white"
                    }}
                  >
                    Proceed Payment
                  </button>
                </Col>
              </Row>

              {cartProducts.filter((product) => product.quantity < 1) && (
                <Modal
                  visible={isModalOpen}
                  onOk={handleOk}
                  onCancel={handleCancel}
                >
                  <p className="px-3 pt-1" style={{ fontWeight: "bold" }}>
                    You have reached the maximum limit.
                  </p>
                  <p className="px-3 pt-1">Are you sure to remove this item.</p>
                </Modal>
              )}
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
