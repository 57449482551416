import React, { useEffect, useState } from "react";
import axios from "axios";
import globalConst from "../helpers/global";
import queryString from "query-string";
import loadingGif from "../assets/loading-gif.gif";
import wentWrong from "../assets/error-gif.gif";
import paySuccess from "../assets/success.gif";
const ReserveTableFallbackDintreero = (props) => {
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(async () => {
    try {
      var query = queryString.parse(props.location.search);
      console.log("id", query.merchant_reference);
      console.log("query.transaction_id", query.transaction_id);
      const response = await axios.post(
        `${globalConst.serverip}api/v1/public/confirmResreveTablePaymentdinterro`,
        { id: query.merchant_reference, transactionId: query.transaction_id },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      if (response.data.status === 200) {
        console.log("response", response);
        setLoading(false);
        console.log("response after payment confirm in dinterro", response);
      } else {
        setLoading(false);
        setIsError(true);
      }
    } catch (error) {
      setLoading(false);
      setIsError(true);
    }
  }, []);

  return loading ? (
    <div className="loading-gif">
      {/* <h3>Loading....</h3> */}
      <img src={loadingGif} alt="Loading" width="80px" />
    </div>
  ) : isError ? (
    <div className="error-page d-flex flex-column justify-content-center align-items-center">
      <img src={wentWrong} alt="Loading" width="150px" />
      <h3>Something went wrong</h3>
    </div>
  ) : (
    <div className="success-payment d-flex flex-column justify-content-center align-items-center">
      <img src={paySuccess} alt="Loading" width="250px" />
      <h3>Payment Successful!</h3>
      <p>
        Thankyou for the Payment. You'll get the confirmation SMS or E-Mail from
        the Restaurant soon.
      </p>
      <a class="orderBtn" href="/restaurant/reservetable/rooster">
        return
      </a>
    </div>
  );
};

export default ReserveTableFallbackDintreero;
